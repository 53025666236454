import Home from "./Home";
import TabloHavale from "./TabloHavale";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import LafzHistory from "./LafzHistory";
import MyDeals from "./MyDeals";

import HomeIcon from "@mui/icons-material/Home";
import CampaignIcon from "@mui/icons-material/Campaign";
import HistoryIcon from "@mui/icons-material/History";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";

const iconWidth = "20px"
const moneyIconWidth = "22px"

const iconTopBarWidth = "28px"

export const TABLO_LIST = [
  {
    tablo: "THT",
    title: "حواله روز",
    icon: <LightModeOutlinedIcon sx={{ width: iconWidth }} />,
    type: "havale"
  },
  {
    tablo: "THF",
    title: "حواله فردا",
    icon: <HourglassEmptyOutlinedIcon sx={{ width: iconWidth }} />,
    type: "havale"
  },
  {
    tablo: "TCT",
    title: "نقدی روز",
    icon: (
      <span className="flex flex-row items-center">
        <LightModeOutlinedIcon sx={{ width: iconWidth }} />
        <AttachMoneyOutlinedIcon sx={{ width: moneyIconWidth }} />
      </span>
    ),
    type: "cash"
  },
  {
    tablo: "TCF",
    title: "نقدی فردا",
    icon: (
      <span className="flex flex-row items-center">
        <HourglassEmptyOutlinedIcon sx={{ width: iconWidth }} />
        <AttachMoneyOutlinedIcon sx={{ width: moneyIconWidth }} />
      </span>
    ),
    type: "cash"    
  },
];

const pages = [
  {
    url: "/",
    title: "خانه",
    component: <Home />,
    icon: <HomeIcon sx={{ width: iconTopBarWidth }} />
  },
  {
    url: "/tablohavale",
    title: "تابلو معاملات",
    component: <TabloHavale TABLO_LIST={TABLO_LIST} />,
    icon: <CampaignIcon sx={{ width: iconTopBarWidth }}/>
  },
  {
    url: "/lafzhistory",
    title: "تاریخچه لفظ ها",
    component: <LafzHistory/>,
    icon: <HistoryIcon  sx={{ width: iconTopBarWidth }}/>
  },
  {
    url: "/mydeals",
    title: "معاملات من",
    component: <MyDeals/>,
    icon: <ChecklistRtlIcon sx={{ width: iconTopBarWidth }}/>
  },

];

export const getPageByUrl = (url) => {
  return pages.find((item) => item.url === url);
};

export const getAllPages = () => {
  return pages;
};
