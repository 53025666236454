
import * as shamsi from 'shamsi-date-converter';


const perisanDays = [
    "یکشنبه",
    "دوشنبه",
    "سه شنبه",
    "چهارشنبه",
    "پنجشنبه",
    "جمعه",
    "شنبه"
]

export const toShamsi = (date) => {
    return shamsi.gregorianToJalali(date).join("/")
}

export const getPersianDay = (date, hasToday) => {
    if (hasToday && toShamsi(date) === toShamsi(new Date()))
    {
        return "امروز"

    }else
    {
        return perisanDays[new Date(date).getDay()]
    }
}

export function timeAgo(input , now)  {
    const date = (input instanceof Date) ? input : new Date(input);
    const formatter = new Intl.RelativeTimeFormat('fa');
    const ranges = {
      years: 3600 * 24 * 365,
      months: 3600 * 24 * 30,
      weeks: 3600 * 24 * 7,
      days: 3600 * 24,
      hours: 3600,
      minutes: 60,
      seconds: 1
    };
    const secondsElapsed = (date.getTime() - now) / 1000;

    for (let key in ranges) {
      if (ranges[key] < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / ranges[key];
        return formatter.format(Math.round(delta), key);
      }
    }
  }

