import {
  Button,
  IconButton,
  InputAdornment,
  Switch,
  useTheme,
} from "@mui/material";
import TextBox from "./TextBox";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useEffect, useState } from "react";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AppService from "../services/AppService";
import { useAtom } from "jotai";
import { authTokenAtom } from "../atoms/authTokenAtom";

function EditUser({ user, isChild, reloadCallback }) {
  const theme = useTheme();

  const [profileTitle, setProfileTitle] = useState("");

  const [authToken, setAuthToken] = useAtom(authTokenAtom)
  
  const [profileBlank, setProfileBlank] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [maxChildren, setMaxChildren] = useState( 0);
  const [viewOnly, setViewOnly] = useState(false);

  const [showResetConfirm, setShowResetConfirm] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  const [systemError, setSystemError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("")


  useEffect( () => {

    if (user)
    {
        setProfileTitle(prev => user.profileTitle)
        setMaxChildren(prev => user.maxChildren ?? 0) 
        setViewOnly(prev => user.viewOnly)
    }

    return () => {
        setShowResetConfirm(prev => false)
        setShowDeleteConfirm(prev => false)
        setSystemError(prev => false)
        setSuccessMessage(prev => "")
    }

  }, [user])

  const submitReset = async () => {
    try{
        setSubmitting(prev => true)
  
        const res = await AppService.resetPassword({
          authtoken: authToken,
          account: user?.username,
        })
  
        if (res && res.data && res.data.status === "OK")
        {
          setSuccessMessage(prev => "رمز عبور با موفقیت ریست شد")
          setShowResetConfirm(prev => false)
  
          if (reloadCallback)
          {
              reloadCallback()
          }
  
  
        }else{
          setSystemError(prev => res?.data?.error || "")
        }
  
        setSubmitting(prev => false)
    
  
      }catch(error)
      {
        setSubmitting(prev => false)
        setSystemError(prev => "مشکلی رخ داد لطفا مجددا تلاش نمایید")
      }
  

  }

  const submitDelete = async () => {

    try{
        setSubmitting(prev => true)
  
        const res = await AppService.deleteUser({
          authtoken: authToken,
          account: user?.username,
        })
  
        if (res && res.data && res.data.status === "OK")
        {
          setSuccessMessage(prev => "کاربر با موفقیت حذف شد")
          setShowDeleteConfirm(prev => false)
  
          if (reloadCallback)
          {
              reloadCallback()
          }
  
  
        }else{
          setSystemError(prev => res?.data?.error || "")
        }
  
        setSubmitting(prev => false)
    
  
      }catch(error)
      {
        setSubmitting(prev => false)
        setSystemError(prev => "مشکلی رخ داد لطفا مجددا تلاش نمایید")
      }
  


  }

  const saveClicked = async () =>    
  {
    setSystemError(prev => "")

    let error = false
    if (!profileTitle || profileTitle.trim().length < 3)
    {
      setProfileBlank(prev => true)
      error = true
    }
    if (error)
    {
      return
    }

    try{
      setSubmitting(prev => true)

      const res = await AppService.updateUser({
        authtoken: authToken,
        account: user?.username,
        profileTitle: profileTitle,
        maxChildren: maxChildren,
        viewOnly: viewOnly
      })

      if (res && res.data && res.data.status === "OK")
      {
        setSuccessMessage(prev => "تغییرات با موفقیت ذخیره شد")

        if (reloadCallback)
        {
            reloadCallback()
        }


      }else{
        setSystemError(prev => res?.data?.error || "")
      }

      setSubmitting(prev => false)
  

    }catch(error)
    {
      setSubmitting(prev => false)
      setSystemError(prev => "مشکلی رخ داد لطفا مجددا تلاش نمایید")
    }



  }


  return (
    <>
      <div className="w-full h-full flex flex-col gap-4 text-white px-10 pb-[50px]">
        <p className="w-full text-center  py-2 flex flex-row justify-center gap-4 items-center">
          <span className="text-gray-300">{"نام کاربری :"}</span>
          <span className="font-bold bg-gray-600 px-2 py-1">
            {user?.username}
          </span>
        </p>
        <p>
          <TextBox
            id="profile"
            label="نام و نام خانوادگی (فارسی)"
            InputAdornment
            fullWidth
            autoComplete={"off"}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlineOutlinedIcon
                      style={{
                        color: theme.palette.primary.light,
                        width: "22px",
                      }}
                    />
                  </InputAdornment>
                ),
              },
            }}
            variant="standard"
            value={profileTitle}
            onChange={(event) => {
              setProfileTitle(event.target.value);
              setProfileBlank(!event.target.value);
            }}
            disabled={submitting}
            error={profileBlank}
          />
        </p>

        {!isChild && (
          <p>
            <p className="flex flex-row justify-between pt-5 items-center">
              <p className="text-gray-100 text-sm">
                {"حداکثر کاربر زیرمجموعه"}
              </p>

              <p className="flex flex-row gap-0 text-white items-center">
                <IconButton
                  onClick={() =>
                    setMaxChildren((prev) => (prev > 0 ? prev - 1 : prev))
                  }
                >
                  <RemoveOutlinedIcon
                    sx={{ color: theme.palette.primary.main }}
                  />
                </IconButton>

                <span className="w-[50px] text-center border text-xl rounded-lg border-yellow-400 py-0.5">
                  {`${maxChildren}`}
                </span>

                <IconButton
                  onClick={() =>
                    setMaxChildren((prev) => (prev < 10 ? prev + 1 : prev))
                  }
                >
                  <AddOutlinedIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>
              </p>
            </p>
          </p>
        )}

        <p className="flex flex-row justify-between items-center pb-5">
          <p className="text-gray-100 text-sm flex flex-row gap-1">
            <span>{"غیرفعال کردن ترید"}</span>
            <span>{"( Read-only )"}</span>
          </p>

          <p className="flex flex-row items-center justify-start w-[95px]">
            <Switch
              checked={viewOnly}
              onChange={(event) => setViewOnly(event.target.checked)}
            />
          </p>
        </p>


        {systemError?.length > 0 && (
          <p className="w-full text-center text-red-200 bg-red-700 p-2 flex flex-row gap-2">
            <span>{"Error: "}</span>
            <span>{`${systemError}`}</span>
          </p>
        )}

        {successMessage?.length > 0 && (
          <p className="w-full text-center text-green-700 bg-green-200 p-2">
            {`${successMessage}`}
          </p>
        )}

        <Button variant="contained" fullWidth onClick={saveClicked} disabled={submitting}>
          {submitting ? "..." : "ذخیره تغییرات"}
        </Button>

        <Button
          color="secondary"
          variant="contained"
          fullWidth
          onClick={() => setShowResetConfirm((prev) => true)}
        >
          {"ریست کردن رمز عبور"}
        </Button>

        <Button
          color="error"
          variant="contained"
          fullWidth
          onClick={() => setShowDeleteConfirm((prev) => true)}
        >
          {"حذف کاربر"}
        </Button>


      </div>

      <Dialog open={showResetConfirm} maxWidth={"xs"} fullWidth>
        <DialogTitle
          sx={{ fontSize: "16px", color: theme.palette.primary.main }}
        >
          ریست کردن رمزعبور مورد تایید است؟
        </DialogTitle>
        <DialogContent
          sx={{
            color: "#fafafa",
            padding: "10px",
            width: "100%",
            fontSize: "16px",
            lineHeight: "28px",
          }}
        >
          <div className="w-full flex flex-row gap-4 items-center justify-center border border-yellow-300 py-2">
            <span className="text-gray-300">کاربر: </span>

            <span>{user?.profileTitle}</span>
          </div>
        </DialogContent>
        <DialogActions className="flex flex-row items-center gap-2">
          <Button
            sx={{ fontSize: "14px", color: "#eee" }}
            onClick={() => setShowResetConfirm((prev) => false)}
            variant="outlined"
          >
            کنسل
          </Button>
          <Button
            sx={{ fontSize: "14px" }}
            color="secondary"
            variant="contained"
            onClick={submitReset}
            disabled = {submitting}

          >
            ریست پسوورد
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showDeleteConfirm} maxWidth={"xs"} fullWidth>
        <DialogTitle
          sx={{ fontSize: "16px", color: theme.palette.primary.main }}
        >
          حذف کاربر مورد تایید است؟
        </DialogTitle>
        <DialogContent
          sx={{
            color: "#fafafa",
            padding: "10px",
            width: "100%",
            fontSize: "16px",
            lineHeight: "28px",
          }}
        >
          <div className="w-full flex flex-row gap-4 items-center justify-center border border-yellow-300 py-2">
            <span className="text-gray-300">کاربر: </span>

            <span>{user?.profileTitle}</span>
          </div>
        </DialogContent>
        <DialogActions className="flex flex-row items-center gap-2">
          <Button
            sx={{ fontSize: "14px", color: "#eee" }}
            onClick={() => setShowDeleteConfirm((prev) => false)}
            variant="outlined"
          >
            کنسل
          </Button>
          <Button
            sx={{ fontSize: "14px" }}
            color="error"
            variant="contained"
            onClick={submitDelete}
            disabled = {submitting}
          >
            حذف کاربر
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditUser;
