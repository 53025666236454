import dateFormat from "dateformat";
import AlarmOutlinedIcon from "@mui/icons-material/AlarmOutlined";
import { useAtom } from "jotai";
import { accountAtom } from "../atoms/accountAtom";
import PanToolIcon from "@mui/icons-material/PanTool";

import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import { TABLO_LIST } from "../pages/_index";
import { getPersianDay, toShamsi } from "../utils/date-utils";
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useTheme } from "@mui/material";

function LafzHistoryItem({ item }) {

  const theme = useTheme()

  const renderLafzStatus = (item) => {
    if (item.status === "new") {
      /// expired
      return (
        <div className="flex flex-row items-center justify-center gap-1 text-xs text-gray-200">
          <span>{"منقضی  "}</span>
          <span>
            <TimerOffOutlinedIcon sx={{ width: "18px" }} />
          </span>
        </div>
      );
    } else if (item.status === "canceled") {
      return (
        <div className="flex flex-row items-center justify-center gap-1 text-xs text-gray-200">
          <span>{"لغو شده "}</span>
          <span className="text-red-500">
            <CloseOutlinedIcon sx={{ width: "24px" }} />
          </span>
        </div>
      );
    } else if (item.status === "deal") {
      return (
        <div className="flex flex-row items-center justify-center gap-1 text-xs text-gray-200">
          <span>
          {item.dealVolume !== item.volume ? 
            " معامله شده"
            :
            " معامله شده "
          }
            
            </span>
          <span className="text-green-500">
            {item.dealVolume === item.volume ? (
              <DoneAllOutlinedIcon sx={{ width: "24px" }} />
            ) : (
              <DoneOutlinedIcon sx={{ width: "24px" }} />
            )}
          </span>
          <span className="text-green-500 text-xs tracking-wider">
            {`${item.dealVolume}/${item.volume}`}
          </span>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div
      key={`lafz-${item.dir}-${item.seq}`}
      className={`py-0 px-2 w-full relative rounded-xl transition-all duration-500 ease-in-out flex flex-col text-white`}
      style={{background: theme.palette.background.paper}}
    >
      {item.timeStamp && (
        <div className="flex flex-row w-full items-center justify-end">
          <p
            className="text-xs text-gray-400 flex flex-row-reverse items-center gap-2  grow-0 "
            dir="ltr"
          >
            <span>{getPersianDay(item.timeStamp, true)}</span>
            <span>{toShamsi(item.timeStamp)}</span>
            <span className="text-[11px]">{`${dateFormat(
              item.timeStamp,
              "H:MM TT"
            )}`}</span>
          </p>
        </div>
      )}

      <div className="flex flex-row justify-between items-center">
        <p
          className={`flex flex-row gap-1 items-center text-sm ${
            item.dir === "buy" ? "text-green-400 " : "text-red-400 "
          }`}
        >
          <span>{`${item.dir === "buy" ? "خرید" : "فروش"}`}</span>

          <span className="text-center">
            {TABLO_LIST.find((r) => r.tablo === item.tablo)?.title || ""}
          </span>

          <span>
            {TABLO_LIST.find((r) => r.tablo === item.tablo)?.icon || ""}
          </span>
        </p>
      </div>

      <div className="flex items-center justify-between">
        <span className="flex flex-row gap-1 items-center">
          <span className="tracking-wide">
            {`${item.price?.toLocaleString("en") || ""}`}
          </span>
          <span className="text-xs text-slate-300">{"تومان"}</span>

          <span className="flex justify-center items-center gap-1 text-sm px-1 rounded"
              style={{background: theme.palette.background.surface}}
          >
            <span>{`${item.volume}`}</span>
            <span className="text-[11px] text-gray-300">{"کیلو"}</span>
          </span>
        </span>

        <span> {renderLafzStatus(item)}</span>
      </div>

      {item.desc?.length > 0 && (
        <div
          className="w-full overflow-x-scroll break-world border-b-1 rounded text-sm py-1 my-1 flex flex-row"
          style={{ color: theme.palette.secondary.main }}
        >
          <InfoOutlinedIcon
            sx={{
              width: "20px",
              marginLeft: "5px",
              color: theme.palette.secondary.main,
            }}
          />

          <span className="border-b border-pink-200">{`${item.desc}`}</span>
        </div>
      )}
 




    </div>
  );
}

export default LafzHistoryItem;
