import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';


 const TextBox = styled(TextField)({
    '& label': {
        transformOrigin: "right !important",
        left: "inherit !important",
        right: "1.75rem !important",
        fontSize: "16px",
        color: "#fff",
        // fontWeight: 400,
        // overflow: "unset",
        
    },
})

export default TextBox