import { useAtom } from "jotai";
import { urlAtom } from "../atoms/urlAtom";
import { getAllPages, getPageByUrl, TABLO_LIST } from "./_index";
import { useEffect, useRef, useState } from "react";
import AppService from "../services/AppService";
import { accountAtom } from "../atoms/accountAtom";
import { authTokenAtom } from "../atoms/authTokenAtom";
import { Button, CircularProgress, IconButton, useTheme } from "@mui/material";
import LafzHistoryItem from "../components/LafzHistoryItem";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';

const LAFZ_DIR = [
  {dir: "buy", title: "لفظ های خرید"},
  {dir: "sell", title: "لفظ های فروش"}
]

const LAFZ_STATUS = [
  {status: "new", title: "منقضی", icon: <TimerOffOutlinedIcon sx={{width:"20px"}}/>},
  {status: "canceled", title : "لغو شده", icon: <CloseOutlinedIcon sx={{width:"20px"}}/>},
  {status: "deal", title: "معامله شده", icon: <DoneAllOutlinedIcon sx={{width:"20px"}}/>}
]

function LafzHistory() {
  const [lafzArray, setLafzArray] = useState([]);

  const [loading, setLoading] = useState(false);

  const [url, setUrl] = useAtom(urlAtom);

  const [account, setAccount] = useAtom(accountAtom);
  const [authToken, setAuthToken] = useAtom(authTokenAtom);

  const [loadMore, setLoadMore] = useState(false);

  const containerRef = useRef(null);
  const lastElementRef = useRef(null);
  const veryLastElementRef = useRef(null);

  const [lastSeq, setLastSeq] = useState(0);

  const [nomoreData, setNomoreData] = useState(false);

  const [firstTime, setFirstTime] = useState(false);

  const [showScrollToEnd, setShowScrollToEnd] = useState(false)

  const [showFilter, setShowFilter] = useState(false)

  const [filterTablo, setFilterTablo] = useState([])
  const [filterLafzDir, setFilterLafzDir] = useState([])
  const [filterLafzStatus, setFilterLafzStatus] = useState([])

  const theme = useTheme()

  useEffect(() => {
    if (authToken && url === "/lafzhistory") {
      loadLafzArray(0);
      setFirstTime(prev => true);
    }

    return () => {
      setLafzArray((prev) => []);
      setNomoreData((prev) => false);
      setLastSeq(0);
      setShowScrollToEnd(prev => false)
    };
  }, [authToken, url, filterLafzDir, filterLafzStatus, filterTablo]);

  useEffect( () => {

    if (url !== "/lafzhistory")
    {
      setFilterLafzDir(prev => [])
      setFilterLafzStatus(prev => [])
      setFilterTablo(prev => [])
      setShowFilter(false)
    }

  }, [url])


  useEffect(() => {
    if (lastSeq > 0) {
      if (lastElementRef.current) {
        containerRef.current.style.overflowY = "hidden"
        containerRef.current.scrollTo({
          top: lastElementRef.current.offsetTop - 20,
          behavior: firstTime ? "smooth" : "instant",
        });
        containerRef.current.style.overflowY = "scroll"

      }
    }
  }, [lastSeq]);

  useEffect(() => {
    if (loadMore && lafzArray && lafzArray.length > 0) {
      const minSeq = getMinSeq(lafzArray);
      loadLafzArray(minSeq);
    }
  }, [loadMore, lafzArray]);



  const scrolled = (event) => {

    if (loading || loadMore)
    {
      event.preventDefault()
      return
    }

    setShowScrollToEnd(prev => veryLastElementRef?.current?.getBoundingClientRect()?.top  > 900)

    const scroll = containerRef.current.scrollTop;
    if (scroll === 0) {
      setLoadMore((prev) => true);
      setTimeout(() => {
        setLoadMore((prev) => false);
      }, 3000);
    }
  };

  const getMinSeq = (array) => {
    let min = 1_000_000_000;
    for (const lafz of array) {
      if (lafz.seq < min) {
        min = lafz.seq;
      }
    }
    return min;
  };

  const getMaxSeq = (array) => {
    let max = 0;
    for (const lafz of array) {
      if (lafz.seq > max) {
        max = lafz.seq;
      }
    }
    return max;
  };

  const loadLafzArray = async (maxSeq) => {
    try {
      if (maxSeq > 0) {
        setFirstTime((prev) => false);
      }

      if (nomoreData && maxSeq > 0) {
        setLoading(prev => false);
        return;
      }

      setLoading(true);
      const res = await AppService.getLafzHistory({
        authtoken: authToken,
        maxSeq: maxSeq,
        filterTablo,
        filterLafzDir,
        filterLafzStatus
      });
      if (res && res.data && res.data.status === "OK") {
        if (res.data.data.length === 0) {
          setNomoreData((prev) => true);
          setLoading((prev) => false);
        } else {
          const data = res.data.data.sort((a, b) => a.seq - b.seq);
          setLastSeq((prev) => getMaxSeq(data));
          setLafzArray((prev) =>
            [...prev, ...data].sort((a, b) => a.seq - b.seq)
          );
        }
        setLoadMore((prev) => false);
      }

      setLoading(prev => false);
    } catch (err) {
      console.error(err);
      setLoading(prev => false);
    }
  };

  const scrollToEnd = () => 
  {
    containerRef.current.scrollTo({
      top: veryLastElementRef.current.offsetTop ,
      behavior: "smooth",
    });
  }

  return (
    <div className="w-full h-full relative"
        style={{background: theme.palette.background.default}}
    >
      <div
        ref={containerRef}
        onScroll={(event) => scrolled(event)}
        className="w-full h-full flex flex-col gap-2 overflow-y-scroll pt-[100px] pb-[20px] pr-[70px] pl-[10px] "
      >
        {loading && (
          <div className="absolute top-10 left-0 w-full flex flex-row items-center justify-center z-20 text-gray-300">
            <CircularProgress color="inherit" size={"20px"} />
          </div>
        )}

        <div
          className={`${
            showScrollToEnd ? "opacity-100 " : "opacity-0 "
          } transition-all duration-300 ease-in-out absolute flex justify-center items-center right-3 bottom-10  rounded-full w-[50px] h-[50px] bg-slate-600 bg-opacity-50 z-20 text-gray-50`}
        >
          <IconButton onClick={scrollToEnd}>
            <KeyboardArrowDownOutlinedIcon />
          </IconButton>
        </div>

        <div
          className={`absolute flex justify-center items-center right-3 top-10  rounded-full w-[50px] h-[50px] bg-slate-600 bg-opacity-50 z-20   ${
            filterTablo.length > 0 ||
            filterLafzDir.length > 0 ||
            filterLafzStatus.length > 0
              ? "text-yellow-400 "
              : "text-gray-50 "
          }`}
        >
          <IconButton onClick={() => setShowFilter((prev) => !prev)} color="inherit">
            <TuneOutlinedIcon />
          </IconButton>
        </div>

        {lafzArray?.map((lafz, index) => (
          <div
            ref={lafz.seq === lastSeq ? lastElementRef : null}
            key={`lafz-${lafz.seq}`}
            className="fade-in"
          >
            <LafzHistoryItem item={lafz} />
          </div>
        ))}
        <div ref={veryLastElementRef}></div>
      </div>

      <div
        className={`${
          showFilter ? "translate-y-0 " : "translate-y-[500px] "
        } rounded-t-3xl transition-all duration-300 ease-in-out fixed w-full pb-[50px] left-0 bottom-0 border-t `}
        style={{ zIndex: 100, background: theme.palette.background.default }}
      >
        <div className="w-full h-full flex flex-col gap-4">
          <p className="px-5 py-2 text-xl text-white border-b flex flex-row justify-between items-center">
            <span className="flex flex-row gap-1">
              <span>
                <TuneOutlinedIcon />
              </span>
              <span>{"فیلترها"}</span>
            </span>
            <span
              className={`${
                filterTablo.length > 0 ||
                filterLafzDir.length > 0 ||
                filterLafzStatus.length > 0
                  ? " "
                  : "hidden "
              }`}
            >
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  setFilterLafzDir((prev) => []);
                  setFilterTablo((prev) => []);
                  setFilterLafzStatus((prev) => []);
                }}
              >
                {"حذف فیلترها"}
              </Button>
            </span>
            <span>
              <IconButton onClick={() => setShowFilter((prev) => false)}>
                <ClearOutlinedIcon />
              </IconButton>
            </span>
          </p>

          <div className="flex flex-row gap-4 px-4">
            {LAFZ_DIR.map((item) => (
              <div
                key={`filter-${item.dir}`}
                className={`flex justify-center flex-col gap-0 py-2 items-center w-full  border rounded-lg transition-all duration-300 ease-in-out ${
                  filterLafzDir.find((r) => r === item.dir)
                    ? "border-yellow-400 text-yellow-400 "
                    : "border-gray-400 text-gray-400 "
                }`}
                onClick={() => {
                  setFilterLafzDir((prev) => {
                    if (prev.find((r) => r === item.dir)) {
                      return prev.filter((r) => r !== item.dir);
                    } else {
                      return [...prev, item.dir];
                    }
                  });
  
                }}
              >
                <span>{`${item.title}`}</span>
              </div>
            ))}
          </div>

          <div className="flex flex-row gap-4 px-4">
            {TABLO_LIST.map((item) => (
              <div
              key={`filter-${item.tablo}`}
                className={`flex justify-center flex-col gap-0  items-center w-full  border rounded-lg transition-all duration-300 ease-in-out ${
                  filterTablo.find((r) => r === item.tablo)
                    ? "border-yellow-400 text-yellow-400 "
                    : "border-gray-400 text-gray-400 "
                }`}
                onClick={() => {
                  setFilterTablo((prev) => {
                    if (prev.find((r) => r === item.tablo)) {
                      return prev.filter((r) => r !== item.tablo);
                    } else {
                      return [...prev, item.tablo];
                    }
                  });
                }}
              >
                <span>{`${item.title}`}</span>
                <span> {item.icon} </span>
              </div>
            ))}
          </div>

          <div className="flex flex-row gap-4 px-4">
            {LAFZ_STATUS.map((item) => (
              <div
                key={`filter-${item.status}`}
                className={`flex justify-center flex-col gap-0  items-center w-full  border rounded-lg transition-all duration-300 ease-in-out ${
                  filterLafzStatus.find((r) => r === item.status)
                    ? "border-yellow-400 text-yellow-400 "
                    : "border-gray-400 text-gray-400 "
                }`}
                onClick={() => {
                  setFilterLafzStatus((prev) => {
                    if (prev.find((r) => r === item.status)) {
                      return prev.filter((r) => r !== item.status);
                    } else {
                      return [...prev, item.status];
                    }
                  });
                }}
              >
                <span>{`${item.title}`}</span>
                <span> {item.icon} </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LafzHistory;
