import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import React, { useEffect } from "react";
import HomeIcon from "@mui/icons-material/Home";
import CampaignIcon from "@mui/icons-material/Campaign";
import HistoryIcon from "@mui/icons-material/History";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import { useAtom } from "jotai";
import { urlAtom } from "../atoms/urlAtom";
import { accountAtom } from "../atoms/accountAtom";
import { authTokenAtom } from "../atoms/authTokenAtom";


function BottomBar() {
  const theme = useTheme();

  const [value, setValue] = React.useState(3);

  const [account, setAccount] = useAtom(accountAtom)
  const [authToken, setAuthToken] = useAtom(authTokenAtom)


  useEffect( () => {

    if (authToken)
    {
      setValue(prev => 3)
      setUrl(prev => "/")
    }

  }, [authToken] )

  const [url, setUrl] = useAtom(urlAtom);

  const gotoUrl = (value) => {
    switch (value) {
      case 3:
        setUrl("/");
        break;
      case 2:
        setUrl("/tablohavale");
        break;
      case 1:
        setUrl("/lafzhistory");
        break;
      case 0:
        setUrl("/mydeals");
        break;
    }
  };

  return (
    <Box>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          gotoUrl(newValue);
        }}

        sx={{paddingBottom: "20px", height:"80px"}}
      >
        
        <BottomNavigationAction label="معاملات" icon={<ChecklistRtlIcon />} />
        <BottomNavigationAction label="تاریخچه" icon={<HistoryIcon />} />
        <BottomNavigationAction label="تابلو" icon={<CampaignIcon sx={value !== 2 ? {color:"white"} : {color: theme.palette.primary.main}}/>} sx={{color: "white"}} />
        <BottomNavigationAction label="خانه" icon={<HomeIcon />} />
      </BottomNavigation>
    </Box>
  );
}

export default BottomBar;
