import { CircularProgress, IconButton, Switch } from "@mui/material";
import { useAtom } from "jotai";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { showSettingsAtom } from "../atoms/showSettingsAtom";
import { ArrowBackIosNew, Settings as SettingsIcon } from "@mui/icons-material";
import AppService from "../services/AppService";
import { authTokenAtom } from "../atoms/authTokenAtom";

function Settings() {
  const theme = useTheme();
  const [showSettings, setShowSettings] = useAtom(showSettingsAtom);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(null);

  const [partipantsList, setParticipantsList] = useState([]);

  const [authToken, setAuthToken] = useAtom(authTokenAtom);

  useEffect(() => {
    if (showSettings && authToken) {
      loadParticipantsList();
    }

    return () => {
      setLoading((prev) => false);
      setSubmitting((prev) => null);
      setParticipantsList((prev) => []);
    };
  }, [showSettings, authToken]);

  const loadParticipantsList = async () => {
    try {
      setLoading((prev) => true);

      const res = await AppService.getParticipantsList({
        authtoken: authToken,
      });
      if (res && res.data && res.data.status === "OK") {
        setParticipantsList((prev) => [...res.data.data]);
      }

      setLoading((prev) => false);
    } catch (err) {
      setLoading((prev) => false);
      console.error(err);
    }
  };

  const partipantChanged = async (event, account) => {
    const trade = event.target.checked ? "yes" : "no";
    const tempArray = [...partipantsList];
    for (const item of tempArray) {
      if (item.account === account) {
        item.trade = trade;
      }
    }

    const noTrades = [];
    for (const item of tempArray) {
      if (item.trade === "no") {
        noTrades.push(item.account);
      }
    }

    try {
      setSubmitting(prev => account);
      await AppService.updateParticipantsList({
        authtoken: authToken,
        noTrades: noTrades,
      });
      setSubmitting(prev => null);
    } catch (err) {
      console.error(err);
      setSubmitting(prev => null);
      loadParticipantsList()
    }
  };

  return (
    <div className="w-full h-screen overflow-hidden flex flex-col gap-0 justify-start">
      <div
        className={`w-full h-[50px] text-white flex flex-row items-center justify-between px-2 shadow-sm grow-0`}
        style={{
          background: theme.palette.background.paper,
          color: "white"//theme.palette.secondary.main,
        }}
      >
        <span className="w-[22px]"></span>

        <span
          className={`text-[18px] transition-all duration-500 ease-in-out flex items-center gap-2`}
        >
          <span>
            <SettingsIcon />
          </span>

          <span>{"تنظیمات"}</span>
        </span>

        <IconButton
          onClick={() => setShowSettings((prev) => false)}
          color="inherit"
        >
          <ArrowBackIosNew color="inherit" sx={{ width: "22px" }} />
        </IconButton>
      </div>

      <div className="w-full h-full flex flex-col gap-2 overflow-scroll relative px-5 py-10">
        {loading && (
          <div className="absolute top-[10px] left-0 w-full flex flex-row items-center justify-center z-20 text-gray-300">
            <CircularProgress color="inherit" size={"20px"} />
          </div>
        )}

        {!loading && (
          <div>
            <div className="w-full relative border rounded-lg border-[#555] flex flex-col py-10 px-5 gap-1">
              <span
                className="text-gray-200 absolute -top-[15px] right-[10px] px-2"
                style={{
                  background: theme.palette.background.default,
                  color: "white"//theme.palette.secondary.main,
                }}
              >
                {"لیست همکاران"}
              </span>

              <p className="text-gray-300 text-sm pb-5 leading-6 text-justify">
                {"در این قسمت می توانید همکارانی که با آنها معامله ندارید را غیرفعال نمایید. در صورت غیرفعال سازی آن افراد نمی توانند لفظ های شما را بگیرند ( و برعکس)"}

              </p>

              {partipantsList?.map((account) => (
                <div
                  key={`account-${account.account}`}
                  className="flex flex-row justify-between items-center text-white py-1 px-5 rounded-xl"
                  style={{ background: theme.palette.background.paperGrad }}
                >
                  <span>{`${account.profileTitle}`}</span>
                  <span style={{ color: theme.palette.primary.main }} className="flex justify-center items-center w-[50px] h-[40px]">
                    {submitting === account.account ? (
                      <CircularProgress color="inherit" size={"24px"} />
                    ) : (
                      <Switch
                        checked={account.trade === "yes"}
                        onChange={(event) =>
                          partipantChanged(event, account.account)
                        }
                      />
                    )}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Settings;
