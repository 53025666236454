import API from './api';

export default class AppService {
  static getLafzHistory = (payload) => {
    return API.post("/api/app/getlafzhistory", payload);
  };

  static getMyDeals = (payload) => {
    return API.post("/api/app/getmydeals", payload);
  };

  static getParticipantsList = (payload) => {
    return API.post("/api/app/getparticipantslist", payload);
  };

  static updateParticipantsList = (payload) => {
    return API.post("/api/app/updateparticipantslist", payload);
  };

  static getTopLevelUsers = (payload) => {
    return API.post("/api/app/gettoplevelusers", payload);
  };

  static getChildrenUsers = (payload) => {
    return API.post("/api/app/getchildrenusers", payload);
  };

  static createUser = (payload) => {
    return API.post("/api/app/createuser", payload);
  };

  static createChildUser = (payload) => {
    return API.post("/api/app/createchilduser", payload);
  };

  static updateUser = (payload) => {
    return API.post("/api/app/updateuser", payload);
  };

  static resetPassword = (payload) => {
    return API.post("/api/app/resetpassword", payload);
  };

  static deleteUser = (payload) => {
    return API.post("/api/app/deleteuser", payload);
  };







}