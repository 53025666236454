
import  { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useAtom } from "jotai";
import { authTokenAtom } from "../atoms/authTokenAtom";
import { socketAtom } from "../atoms/socketAtom";
import { accountAtom } from "../atoms/accountAtom";
import {Manager} from "socket.io-client";
import { WebSocketEndpoint } from "../services/Endpoint";
import { messageRecieved } from "../utils/subscriber";
import { sendMessage } from "../utils/publisher";
import { decrypt } from "../utils/aes-wrapper";
import { clientidAtom } from "../atoms/clientidAtom";
import { liveSocketAtom } from "../atoms/liveSocketAtom";

function Connection() {
  const theme = useTheme();

  const [authToken, setAuthToken] = useAtom(authTokenAtom);
  const [socket, setSocket] = useAtom(socketAtom);
  const [account, setAccount] = useAtom(accountAtom);
  const [clientid, setClientid] = useAtom(clientidAtom)

  const [liveSocket, setLiveSocket] = useAtom(liveSocketAtom)

  useEffect(() => {
    if (authToken && clientid)
    {
      requestForSignIn();
    }
    return () => {
      closeSocket(socket)
    }

  }, [authToken, clientid]);


  const requestForSignIn = async () => {

    if (!authToken || !clientid) {
      return;
    }

    console.log("requestForSignIn")

    setSocket(null)

    const manager = new Manager(WebSocketEndpoint, {
      reconnectionDelayMax: 10000,
      autoConnect: true,
    })

    const newSocket = manager.socket("/", {
      auth: {
        token: authToken,
        clientid: clientid
      }
    })
    

    newSocket.on("connect", () => {

      console.log("socket connected")
      setLiveSocket(true)

    });


    newSocket.on("connect_error", (error) => {
      setLiveSocket(false)
      if (newSocket.active) {
        console.log("connect_error: temporary failure, the socket will automatically try to reconnect")
      } else {
        console.log("the connection was denied by the server")
        console.log(error.message);
      }
    });


    newSocket.on("unauthorized", (reason) => {
      console.error("Unauthorized:", reason);
      
      setSocket(null)
      setAuthToken(null);
      setAccount(null)
      localStorage.removeItem("authToken");

    });

    newSocket.on("disconnect", (reason) => {
      setLiveSocket(false)
      if (newSocket.active) {
        console.log("connect_error: temporary failure, the socket will automatically try to reconnect")
      } else {
        console.log("the connection was denied by the server")
        console.log(reason);
      }
    });

    newSocket.on("authorized", (data) => {
      const { username, profileTitle, parent, viewOnly, admin, nameSpace } = data;

      // console.log( { username, profileTitle, parent, viewOnly, admin })

      setAccount({
        username: username,
        profileTitle: profileTitle,
        parent: parent,
        viewOnly: viewOnly,
        admin: admin,
        nameSpace: nameSpace
      });

      setSocket(newSocket)

      sendMessage({type:"requestTablo"} , newSocket)
      sendMessage({type:"requestStats"} , newSocket)
      sendMessage({type:"requestGoldPrice"} , newSocket)
      sendMessage({type:"requestDollarPrice"} , newSocket)
      sendMessage({type:"requestTopLafzes"} , newSocket)


    });

    newSocket.on("message", (data) => {
      let msg_dec = null;
      try {
        msg_dec = decrypt(
          null,
          null,
          data
        );
        msgReceived(JSON.parse(msg_dec));
      } catch (err) {
        console.error(err);
      }
    });



  };

  const msgReceived = (msg) => {
    // console.log("message received: ", msg)
    messageRecieved(msg);
  };

  const closeSocket = async (_socket) => {
    try {
      if (_socket) {
        _socket.disconnect();
      }
      setSocket(null);
      setAccount(null);
    } catch (err) {}
  };

  return (
    <>
    </>
  );
}

export default Connection;
