import dateFormat from "dateformat";
import AlarmOutlinedIcon from "@mui/icons-material/AlarmOutlined";
import { useAtom } from "jotai";
import { accountAtom } from "../atoms/accountAtom";
import PanToolIcon from "@mui/icons-material/PanTool";

import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import {TABLO_LIST} from "../pages/_index"
import { useTheme } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function LafzTablo({
  liveTablo,
  liveTabloOld,
  item,
  onClick,
  now,
  onVolumeClicked,
}) {
  const [account, setAccount] = useAtom(accountAtom);

  const theme = useTheme()

  const arr = [[1], [1, 2], [1, 2, 3]];

  return (
    <div
      onClick={onClick}
      key={`lafz-${item.dir}-${item.seq}`}
      className={`${
        liveTablo.find((m) => m.seq === item.seq) &&
        !liveTabloOld.find((m) => m.seq === item.seq)
          ? `slide-in-${item.dir === "buy" ? "right" : "left"} `
          : " "
      }   
  
		  py-0 px-0 w-full mb-[30px] relative rounded-t-xl transition-all duration-500 ease-in-out  flex flex-col text-white`}
      style={{ background: theme.palette.background.paper }}
    >
      {item.timeStamp && (
        <div className="flex flex-row gap-0 w-full items-center">
          <div className="w-full h-[7px] bg-gray-400 flex justify-end rounded-b-xl">
            <div
              className="h-full rounded-b-xl transition-all duration-500 ease-in-out"
              style={{
                background: "#00edd5", //theme.palette.secondary.main,
                width: `${
                  (60 -
                    Math.round(
                      (now.getTime() - Date.parse(item.timeStamp)) / 1000
                    )) *
                  1.66
                }%`,
              }}
            ></div>
          </div>
          <p
            className="text-xs text-gray-400 flex flex-row items-center gap-1  grow-0 w-[100px]"
            dir="ltr"
          >
            <span className="text-[10px] text-center">{`${dateFormat(
              item.timeStamp,
              "H:MM TT"
            )}`}</span>
          </p>
        </div>
      )}

      <div className="flex flex-row justify-between items-center">
        <p
          className={`flex flex-row gap-1 items-center text-xs ${
            item.dir === "buy" ? "text-green-400 " : "text-red-400 "
          }`}
        >
          <span>{`${item.dir === "buy" ? "خرید" : "فروش"}`}</span>

          <span className="text-center">
            {TABLO_LIST.find((r) => r.tablo === item.tablo)?.title || ""}
          </span>

          <span>
            {TABLO_LIST.find((r) => r.tablo === item.tablo)?.icon || ""}
          </span>
        </p>
      </div>

      <span className="flex flex-row gap-1 items-center">
        <span className="tracking-wide">
          {`${item.price?.toLocaleString("en") || ""}`}
        </span>
        <span className="text-xs text-slate-300">{"تومان"}</span>
        {item.issuer === account?.username && (
          <span
            className="flex justify-center items-center gap-1 text-sm px-1 rounded"
            style={{ background: theme.palette.background.surface }}
          >
            <span>{`${item.volume}`}</span>
            <span className="text-xs text-gray-300">{"کیلو"}</span>
          </span>
        )}
      </span>

      {item.volume > 0 && item.issuer !== account?.username && (
        <div className="absolute h-[25px] w-full -bottom-[25px] left-0 flex flex-row justify-between gap-1">
          {arr[item.volume - 1].map((kilo) => (
            <div
              onClick={() => onVolumeClicked(kilo)}
              className="flex justify-center items-center rounded-b-xl w-full bg-slate-600"
              style={{ background: theme.palette.background.surface }}
            >
              {`${kilo}`}
            </div>
          ))}
        </div>
      )}

      {item.desc?.length > 0 && (
        <div
          className="w-full overflow-hidden break-world border-b-1 rounded text-sm py-1 my-1 flex flex-row"
          style={{ color: theme.palette.primary.main }}
        >
          <InfoOutlinedIcon
            sx={{
              width: "20px",
              marginLeft: "5px",
              color: theme.palette.primary.main,
            }}
          />

          <span className="border-b border-yellow-300">{`${item.desc}`}</span>
        </div>
      )}
    </div>
  );
}

export default LafzTablo;
