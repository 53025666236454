import dateFormat from "dateformat";
import AlarmOutlinedIcon from '@mui/icons-material/AlarmOutlined';
import { useAtom } from "jotai";
import { Button, CircularProgress, useTheme } from "@mui/material";
import { useState } from "react";
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {TABLO_LIST} from "../pages/_index"


function MyLafz({ item, now, onCancel }) {

  const theme = useTheme()

  const [submitting , setsubmitting] = useState(false)
  const [submitSeq , setSubmitSeq] = useState(0)

  const cancelClicked = () =>
  {

    onCancel()
    setsubmitting(prev => true)
    setSubmitSeq(prev => item.seq)
    setTimeout(() => {
      setsubmitting(prev => false)
    }, 3000);
  }

  return (
    <div
      key={`my-lafz-${item?.seq}`}
      className={`py-1 px-2 w-full overflow-hidden flex flex-col gap-0 text-white border-b border-gray-500`}
      style={{ background: theme.palette.background.paperGrad }}
    >
      <div className="opacity-80 text-sm flex flex-row items-center justify-between">
        <span className="text-slate-50 border-b">{"سفارش فعال"}</span>

        <Button
          color="error"
          onClick={cancelClicked}
          variant="text"
          sx={{ width: "120px" }}
        >
          <span
            className={`${
              submitting && submitSeq === item.seq ? "hidden " : " "
            }`}
          >
            {"لغو سفارش"}
          </span>

          <span
            className={`${
              submitting && submitSeq === item.seq ? " " : "hidden "
            } flex justify-center items-center`}
          >
            <CircularProgress color="error" size={"28px"} />
          </span>
        </Button>
      </div>

      <div className="flex flex-row items-center gap-1 px-2">
        <span
          className={` flex flex-row items-center gap-1 ${
            item?.dir === "buy" ? "text-green-400 " : "text-red-400 "
          } `}
        >
          <span>{`${item?.dir === "buy" ? "خرید" : "فروش"}`}</span>

          <p className="flex flex-row items-center text-sm  ">
            <span className=" text-center">
              {TABLO_LIST.find((r) => r.tablo === item.tablo)?.title || ""}
            </span>
            <span className="pr-1 ">
              {TABLO_LIST.find((r) => r.tablo === item.tablo)?.icon || ""}
            </span>
          </p>
        </span>

        <p className="text-left flex flex-row gap-1 w-fit items-center rounded-t-xl">
          <span>{`${item?.volume || ""}`}</span>
          <span className="text-xs text-slate-300">{"کیلو"}</span>
        </p>

        <span className="flex flex-row gap-2 items-center">
          <span className="tracking-wide">
            {`${item?.price?.toLocaleString("en") || ""}`}
          </span>
          <span className="text-xs text-slate-300">{"تومان"}</span>
        </span>
      </div>

      {item?.timeStamp && (
        <div className="flex flec-row gap-1 items-center">
          <div className="w-full h-[6px] bg-gray-400 flex justify-end rounded-xl">
            <div
              className="h-full rounded-xl transition-all duration-500 ease-in-out"
              style={{
                background: "#00edd5",//theme.palette.secondary.main,
                width: `${
                  (60 -
                    Math.round(
                      (now.getTime() - Date.parse(item?.timeStamp)) / 1000
                    )) *
                  1.66
                }%`,
              }}
            ></div>
          </div>
          <div
            className="rotate-hour-glass"
            style={{ color: "#00edd5" }}
          >
            <HourglassTopOutlinedIcon sx={{ width: "18px" }} />
          </div>
        </div>
      )}

      {item.desc?.length > 0 && (
        <div
          className="w-full overflow-hidden break-world border-b-1 rounded text-sm py-1 my-1 flex flex-row"
          style={{ color: theme.palette.primary.main }}
        >
          <InfoOutlinedIcon
            sx={{
              width: "20px",
              marginLeft: "5px",
              color: theme.palette.primary.main,
            }}
          />

          <span className="border-b border-yellow-300">{`${item.desc}`}</span>
        </div>
      )}
    </div>

  );
}

export default MyLafz;
