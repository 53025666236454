import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./components/Login";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useAtom } from "jotai";
import { authTokenAtom } from "./atoms/authTokenAtom";
import Connection from "./components/Connection";
import Layout from "./components/Layout";
import { accountAtom } from "./atoms/accountAtom";
import ConnectingView from "./components/ConnectingView";
import uuid from 'uuid-random';
import { clientidAtom } from "./atoms/clientidAtom";
import { socketAtom } from "./atoms/socketAtom";
import { liveSocketAtom } from "./atoms/liveSocketAtom";


const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: '#ffc800',
    },
    secondary: {
      main: '#ffd6fa',
    },
    background: {
      default: '#131313',
      paper: '#232323',
      surface: '#454545',
      paperGrad: "linear-gradient(45deg, rgba(35,35,35,1) 0%, rgba(69,69,69,1) 100%)",
    },
  },
  direction: "rtl",
  shape: {
    borderRadius: 4,
  },

  typography: {
    fontFamily: '"Rubik", sans-serif',
    fontSize: 16,
  },
});

function App() {



  const [authToken, setAuthToken] = useAtom(authTokenAtom);
  const [showLogin, setShowLogin] = useState(false);

  const [account, setAccount] = useAtom(accountAtom)

  const [socket, setSocket] = useAtom(socketAtom)
  const [liveSocket, setLiveSocket] = useAtom(liveSocketAtom)


  const [clientid, setClientid] = useAtom(clientidAtom)


  useEffect(() => {
    if (!authToken) {
      const token = localStorage.getItem("authToken");
      if (token) {
        setAuthToken(token);
        setShowLogin(false);
      } else {
        setShowLogin(true);
      }
    } else {
      setShowLogin(false);
    }
  }, [authToken]);


useEffect( () => {
  if (!clientid)
  {
    const client_id = localStorage.getItem("client-id");
    if (!client_id)
    {
      const random_id = uuid()
      localStorage.setItem("client-id", random_id)
      setClientid(random_id)
    }else
    {
      setClientid(client_id)
    }
  }

}, [clientid])

  return (
    <ThemeProvider theme={theme} mode={"dark"}>
      <div
        className={`w-full h-screen overflow-hidden`}
        style={{ background: theme.palette.background.default }}
      >
        <div
          className={`fixed w-full  ${
            showLogin
              ? "translate-x-0 opacity-100 "
              : "translate-x-full opacity-0 "
          } transition-all duration-500 ease-in-out `}
        >
          <Login />
        </div>

        {authToken && (!socket || !liveSocket) ? <ConnectingView /> : <></>}

        {authToken ? <Layout /> : <></>}

        <Connection />

        {/* {authToken &&  <Connection /> } */}

      </div>
    </ThemeProvider>
  );
}

export default App;
