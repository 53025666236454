import {
  AccountCircle,
  ArrowBackIosNew,

} from "@mui/icons-material";
import {  IconButton, Snackbar } from "@mui/material";
import { useAtom } from "jotai";
import { socketAtom } from "../atoms/socketAtom";
import { useTheme } from "@mui/material/styles";
import { accountAtom } from "../atoms/accountAtom";
import React, { useEffect, useState } from "react";
import { authTokenAtom } from "../atoms/authTokenAtom";
import AccountMenu from "./AccountMenu";
import { changePasswordSnackAtom } from "../atoms/changePasswordSnackAtom";
import { urlAtom } from "../atoms/urlAtom";
import { getPageByUrl } from "../pages/_index";
import { endAllSubscriptions } from "../utils/subscriber";


function TopBar() {
  const theme = useTheme();
  const [account, setAccount] = useAtom(accountAtom);
  const [authToken, setAuthToken] = useAtom(authTokenAtom);
  const [socket, setSocket] = useAtom(socketAtom);
  const [accountDialog, setAccountDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showSnackbar, setShowSnackBar] = useAtom(changePasswordSnackAtom)
  const [url, setUrl] = useAtom(urlAtom)
  const [currentPage, setCurrentPage] = useState(null)

  const [titleAnimate, setTitleAnimate] = useState(false)

  useEffect(() => {

    if (url)
    {
      setCurrentPage(getPageByUrl(url))
      setTitleAnimate(true)
      setTimeout(() => {
        setTitleAnimate(false)
      }, 1000);
    }

  }, [url])
  

  const submitLogout = () =>
  {
    if (socket)
    {
      socket.disconnect();
    }
    setSocket(prev => null)
    setAccount(prev => null)
    setAuthToken(prev => null)
    endAllSubscriptions()
    localStorage.removeItem("authToken")    
  }

  return (
    <div
      className={`w-full h-[50px] text-white flex flex-row items-center justify-between px-2 shadow-sm grow-0`}
      style={{ background: theme.palette.background.paper }}
    >
      <IconButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setAccountDialog(true);
        }}
      >
        <AccountCircle sx={{ width: "32px", height: "32px" }}  style={{color:  theme.palette.primary.main}}/>
      </IconButton>

      <AccountMenu
        anchorEl={anchorEl}
        open={accountDialog}
        onClose={() => setAccountDialog(false)}
        account={account}
        submitLogout={submitLogout}
      />

      <span
        className={`text-[18px] ${
          titleAnimate ? "fade-in " : " "
        } transition-all duration-500 ease-in-out flex items-center gap-2`}
         style={{color:  theme.palette.primary.main}}
      >
        <span>{currentPage?.icon}</span>

        <span>{currentPage?.title}</span>
      </span>

      {currentPage?.backURL && currentPage?.url !== currentPage?.backURL ? (
        <IconButton onClick={() => setUrl(currentPage?.backURL ?? "/")}>
          <ArrowBackIosNew sx={{ width: "22px" }} />
        </IconButton>
      ) : (
        <span className="w-[50px]"></span>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={showSnackbar}
        onClose={() => setShowSnackBar(false)}
        message="رمز عبور شما با موفقیت تفییر کرد"
        ContentProps={{
          sx: {
            background: "#0b9c06",
            color: "white",
          },
        }}
      />
    </div>
  );
}

export default TopBar;
