import dateFormat from "dateformat";
import AlarmOutlinedIcon from "@mui/icons-material/AlarmOutlined";
import { useAtom } from "jotai";
import { accountAtom } from "../atoms/accountAtom";
import PanToolIcon from "@mui/icons-material/PanTool";

import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import { TABLO_LIST } from "../pages/_index";
import { getPersianDay, toShamsi } from "../utils/date-utils";
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { useTheme } from "@mui/material";

function MyDealItem({ item, searchText }) {

  const [account, setAccount] = useAtom(accountAtom)

  const theme = useTheme()

  const renderSearchText = (text) => {
    if (searchText?.trim().length > 0) {
      const parts = text.split(new RegExp(`(${searchText})`, "gi"));
      return (
        <span>         
          {parts.map((part, i) => (
            <span
              key={i}
              style={
                part.toLowerCase() === searchText.toLowerCase()
                  ? { color: theme.palette.primary.main, background: "#111" }
                  : {}
              }
            >
              {part}
            </span>
          ))}
        </span>
      );
    } else {
      return <span> {text} </span>;
    }
  };

  const isChildren = (_account) =>
  {
    return !(item.buyerIssuer === _account?.username || item.sellerIssuer === _account?.username)
  }

  return (
    <div
      key={`deal-${item.dir}-${item.seq}`}
      className={`pb-2 pt-1 px-2 w-full relative rounded-xl transition-all duration-500 ease-in-out flex flex-col gap-1 text-white ${
        isChildren(account) ? "border border-yellow-300 " : " "
      }`}
      style={{ background: theme.palette.background.paperGrad }}
    >
      <div
        className="flex flex-row items-center justify-between"
        style={{ color: theme.palette.secondary.main }}
      >
        <div className="flex flex-row items-center justify-end">
          <p
            className="text-sm flex flex-row-reverse items-center gap-2  grow-0 "
            dir="ltr"
          >
            <span>{getPersianDay(item.timeStamp, true)}</span>
            <span>{toShamsi(item.timeStamp)}</span>
            <span>{"-"}</span>
            <span className="">{`${dateFormat(
              item.timeStamp,
              "H:MM TT"
            )}`}</span>
          </p>
        </div>
        <div>
          <HandshakeOutlinedIcon sx={{ width: "24px" }} />
        </div>
      </div>

      <div className="flex flex-row justify-between items-center pb-1 px-0">
        <p
          className={`flex flex-row gap-1 items-center ${
            item.buyerIssuer === account?.username ||
            item.buyerAccount === account?.username
              ? "text-green-400 "
              : "text-red-400 "
          }`}
        >
          <span>{`${
            item.buyerIssuer === account?.username ||
            item.buyerAccount === account?.username
              ? "خرید"
              : "فروش"
          }`}</span>

          <span className="text-center">
            {TABLO_LIST.find((r) => r.tablo === item.tablo)?.title || ""}
          </span>

          <span>
            {TABLO_LIST.find((r) => r.tablo === item.tablo)?.icon || ""}
          </span>
        </p>

        <p className="text-slate-200"></p>
      </div>

      <div className="flex items-center justify-between">
        <span className="flex flex-row gap-1 items-baseline">
          <span className="text-sm text-slate-300 w-[100px] text-center">
            {"قیمت "}
          </span>
          <span className="flex flex-row items-baseline gap-1">
            <span className="tracking-wide">
              {`${item.price?.toLocaleString("en") || ""}`}
            </span>
            <span className="text-xs text-slate-300">{"تومان"}</span>
          </span>
        </span>
      </div>

      <div className="flex items-center justify-between">
        <span className="flex flex-row gap-1 items-baseline">
          <span className="text-sm text-slate-300 w-[100px] text-center">
            {"مقدار "}
          </span>
          <span className="flex justify-center items-baseline gap-1 px-1 rounded">
            <span>{`${item.volume}`}</span>
            <span className="text-[11px] text-gray-300">{"کیلو"}</span>
          </span>
        </span>
      </div>

      <div className="flex items-center justify-between">
        <span className="flex flex-row gap-1 items-baseline">
          <span className="text-sm text-slate-300 w-[100px] text-center">
            {"طرف معامله  "}
          </span>
          <span>
            {renderSearchText(
              isChildren(account)
                ? item.buyerAccount === account?.username
                  ? item.sellerProfile
                  : item.buyerProfile
                : item.buyerIssuer === account?.username
                ? item.sellerProfile
                : item.buyerProfile
            )}
          </span>
        </span>
      </div>

      {isChildren(account) && (
        <div className="flex items-center justify-between border border-yellow-300 rounded-full py-1">
          <span className="flex flex-row gap-1 items-baseline">
            <span className="text-sm text-slate-300 w-[100px] text-center">
              {"اجرای معامله  "}
            </span>
            <span>
              {renderSearchText(
                item.buyerAccount === account?.username
                  ? item.buyerProfile?.substring(
                      0,
                      item.buyerProfile?.indexOf("(")
                    )
                  : item.sellerProfile?.substring(
                      0,
                      item.sellerProfile?.indexOf("(")
                    )
              )}
            </span>
          </span>
        </div>
      )}

      {item.desc?.length > 0 && (
      <div className="flex items-center justify-between">
      <span className="flex flex-row gap-1 items-baseline">
        <span className="text-sm text-slate-300 w-[100px] text-center">
          {"شرط  "}
        </span>
        <p
        >
            {item.desc}
        </p>
      </span>
    </div>
      )}

      <div className="flex items-center justify-between">
        <span className="flex flex-row gap-1 items-baseline">
          <span className="text-sm text-slate-300 w-[100px] text-center">
            {"برای  "}
          </span>
          <p
            className="flex flex-row-reverse items-center gap-2  grow-0 "
            dir="ltr"
          >
            <span>{getPersianDay(item.settlementDate, false)}</span>
            <span className="tracking-wider">
              {toShamsi(item.settlementDate)}
            </span>
          </p>
        </span>
      </div>

      <div className="flex items-center justify-between">
        <span className="flex flex-row gap-1 items-baseline">
          <span className="text-sm text-slate-300 w-[100px] text-center">
            {"شماره پیگیری  "}
          </span>
          <p
            className="flex flex-row-reverse items-center gap-2 grow-0 text-sm text-slate-300"
            dir="ltr"
          >
            {item.seq}
          </p>
        </span>
      </div>
    </div>
  );
}

export default MyDealItem;
