import { useTheme } from "@emotion/react";
import { Key, Logout, Settings } from "@mui/icons-material";
import { Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import ChangePasswordDialog from "./ChangePasswordDialog";
import { useState } from "react";
import { APP_VERSION } from "../Global";
import { useAtom } from "jotai";
import { showSettingsAtom } from "../atoms/showSettingsAtom";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import { showUserManagerAtom } from "../atoms/showUserManagerAtom";
import { showChildrenManagerAtom } from "../atoms/showChildrenManagerAtom";

function AccountMenu({ anchorEl, open, onClose, account, submitLogout }) {
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);

  const [showSettings, setShowSettings] = useAtom(showSettingsAtom);

  const [showUserManager, setShowUserManager] = useAtom(showUserManagerAtom)
  const [showChildrenManager, setShowChildrenManager] = useAtom(showChildrenManagerAtom)

  const menuItemFontSize = "16px";

  const theme = useTheme();
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={onClose}
        onClick={onClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              bgcolor: "background.menu",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.menu",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <p className="text-sm opacity-80 tracking-wider flex flex-col gap-2">
            <span>{account?.username ?? ""}</span>
            <span className="text-sm opacity-80">
              {account?.profileTitle ?? ""}
            </span>
          </p>
        </MenuItem>
        <Divider />

        {account?.admin && (
          <MenuItem onClick={() => setShowUserManager(prev => true)} sx={{ fontSize: menuItemFontSize }}>
            <ListItemIcon>
              <ManageAccountsOutlinedIcon fontSize="small" />
            </ListItemIcon>
            مدیریت کاربران
          </MenuItem>
        )}

        <MenuItem onClick={() => setShowChildrenManager(prev => true)} sx={{ fontSize: menuItemFontSize }}>
          <ListItemIcon>
            <Diversity3OutlinedIcon fontSize="small" />
          </ListItemIcon>
          مدیریت زیرمجموعه
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => setChangePasswordDialog((prev) => true)}
          sx={{ fontSize: menuItemFontSize }}
        >
          <ListItemIcon>
            <Key fontSize="small" />
          </ListItemIcon>
          تغییر رمز
        </MenuItem>

        <MenuItem
          onClick={() => setShowSettings((prev) => true)}
          sx={{ fontSize: menuItemFontSize }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          تنظیمات
        </MenuItem>
        <MenuItem onClick={() => submitLogout()}>
          <ListItemIcon>
            <Logout fontSize="small" color="error" />
          </ListItemIcon>
          <span style={{ color: `${theme.palette.error.main}` }}> خروج </span>
        </MenuItem>

        <Divider />
        <MenuItem sx={{ fontSize: "12px", color: "#999" }}>
          <div className="flex flex-row justify-between  gap-0 w-full">
            <span>{`نسخه`}</span>
            <span className="pl-2">{`${APP_VERSION}`}</span>
          </div>
        </MenuItem>
      </Menu>

      <ChangePasswordDialog
        open={changePasswordDialog}
        onClose={() => setChangePasswordDialog(false)}
      />
    </>
  );
}

export default AccountMenu;
