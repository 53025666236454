import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardHideIcon from "@mui/icons-material/KeyboardHide";
import { Button, IconButton, useTheme } from "@mui/material";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { useState } from "react";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const priceSteps = [1000, -1000, 5000, -5000];

function Numpad({
  priceStepsClicked,
  numpadOpen,
  setNumpadOpen,
  text,
  label,
  textChanged,
  error,
  prefixDigits
}) {
  const theme = useTheme();


  const prefixWithZero = (str) => {
    const len = str?.length 

    if (len === 1)
    {
        return `00${str}`

    }else if (len === 2)
    {
        return `0${str}`

    }else
    {
        return str
    }

  }

  return (
    <div
      className="relative w-full h-full flex flex-row justify-center items-center"
      style={{ direction: "ltr" }}
    >
      <div
        className="flex flex-col mx-2 w-full"
        onClick={() => setNumpadOpen((prev) => !prev)}
      >
        <div
          className={`w-full h-[40px] flex flex-row items-center justify-center gap-0  rounded border ${
            error ? "border-red-500 " : "border-white "
          } `}
        >

          <span className={`text-green-300 w-[10px] transition-all duration-300 ease-in-out delay-300 ${prefixDigits?.length > 0 ? "opacity-100 " : "opacity-0 "} `}>
            <DoneOutlinedIcon/>
          </span>  

          <span className={`text-gray-400 text-lg w-full text-right ${prefixDigits?.length > 0 ? "opacity-100 " : "opacity-0 "} transition-all duration-300 ease-in-out`}>
            {prefixDigits?.length > 0 ? `${prefixDigits},` : ""}
          </span>

          <span
            className={`${
              text.length > 0 ? "text-white  " : "text-gray-400 "
            } text-xl w-full text-center`}
          >
            {`${text.length > 0 ? prefixWithZero(text) : "XXX"}`}
          </span>

      
            <span className={`text-gray-400 text-lg w-full text-left transition-all duration-300 ease-in-out ${prefixDigits?.length > 0 ? "opacity-100 " : "opacity-0 "}`}>
              {`,000`}
              <span className="text-xs px-1 text-gray-300">{"تومان"}</span>
            </span>
      
        </div>
        <span
          className={`text-xs w-full text-left ${
            error ? "text-red-500 " : "text-gray-400 "
          }  px-2 py-1`}
        >
          {error ? "قیمت خارج از محدوده مجاز" : `${label}` } 
        </span>
      </div>

      <div
        className={`${
          numpadOpen ? "h-[250px] opacity-100 border-t " : "h-0 opacity-100 "
        } flex flex-row overflow-hidden absolute w-screen bottom-[70px] border-[#555] -right-5 z-10 transition-all duration-300 ease-in-out`}
        style={{ background: "rgba(0,0,0,0.9)" }}
      >
        <div className="flex flex-col h-full w-[100px] border-r border-[#333] items-center justify-around px-5">
          {priceSteps.map((item) => (
            <div className="w-full">
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                className="flex flex-row justify-center items-center gap-1"
                onClick={() => priceStepsClicked(item)}
              >
                <span>
                  {item > 0 ? (
                    <AddOutlinedIcon sx={{ width: "24px" }} />
                  ) : (
                    <RemoveOutlinedIcon sx={{ width: "24px" }} />
                  )}
                </span>

                <span className="tracking-wide text-xl">{Math.abs(item / 1000).toLocaleString("en")}</span>
              </Button>
            </div>
          ))}
        </div>

        <div className="flex flex-col h-full w-full">
          <div className="flex flex-row items-center justify-end w-full h-[50px] px-3 text-[#eee] gap-1">
            <span className="text-xl font-bold tracking-wide w-full bg-[#222] px-2">{`${text?.length > 0 ?  text : "0"}`}</span>
            <p className="flex flex-row items-center gap-3">
              {/* <span className="text-xl text-[#aaa]">{",000"}</span> */}
              <IconButton
                sx={{ color: theme.palette.secondary.main, width: "35px" }}
                onClick={() => setNumpadOpen(false)}
              >
                <KeyboardHideIcon />
              </IconButton>
            </p>
          </div>

          <div className="grid grid-cols-3 w-full h-full">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, -1, 0, -2].map((item, index) => (
              <>
                {item > -1 && (
                  <Button
                    key={`key-${index}`}
                    className={`flex justify-center items-center w-full`}
                    sx={{ fontSize: "20px", color: "#ddd" }}
                    onClick={() => {if (text?.length < 3) textChanged(`${text}${item}`)}}
                  >
                    {`${item}`}
                  </Button>
                )}

                {item === -1 && (
                  <Button
                    key={`key-${index}`}
                    className="flex justify-center items-center"
                    sx={{ fontSize: "20px", color: "#ccc" }}
                    onClick={() => {
                      textChanged("");
                    }}
                  >
                    <ClearIcon sx={{ width: "28px" }} />
                  </Button>
                )}

                {item === -2 && (
                  <Button
                    key={`key-${index}`}
                    className="flex justify-center items-center"
                    sx={{ fontSize: "20px", color: "#ccc" }}
                    onClick={() =>
                      textChanged(text.substring(0, text.length - 1))
                    }
                  >
                    <BackspaceOutlinedIcon sx={{ width: "28px" }} />
                  </Button>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Numpad;
