import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Switch,
} from "@mui/material";
import { useAtom } from "jotai";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { showSettingsAtom } from "../atoms/showSettingsAtom";
import { ArrowBackIosNew, Settings as SettingsIcon, ClearOutlined } from "@mui/icons-material";
import AppService from "../services/AppService";
import { authTokenAtom } from "../atoms/authTokenAtom";
import { showUserManagerAtom } from "../atoms/showUserManagerAtom";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TextBox from "./TextBox";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import { showChildrenManagerAtom } from "../atoms/showChildrenManagerAtom";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import EditUser from "./EditUser";
import DoNotTouchOutlinedIcon from '@mui/icons-material/DoNotTouchOutlined';

function ChildrenManager() {
  const theme = useTheme();
  const [showUserManager, setShowUserManager] = useAtom(showChildrenManagerAtom);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(null);

  const [userList, setUserList] = useState([]);

  const [authToken, setAuthToken] = useAtom(authTokenAtom);

  const [systemError, setSystemError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("")

  const [username, setUsername] = useState("");
  const [userBlank, setUserBlank] = useState(false);

  const [profileTitle, setProfileTitle] = useState("");
  const [profileBlank, setProfileBlank] = useState(false);

  const [maxChildren, setMaxChildren] = useState(0)
  const [viewOnly, setViewOnly] = useState(false)

  const [filterText, setFilterText] = useState("")

  const [editItem, setEditItem] = useState(null)

  useEffect(() => {
    if (showUserManager && authToken) {
      loadUserList();
    }

    return () => {
      setLoading((prev) => false);
      setSubmitting((prev) => null);
      setUserList((prev) => []);
      setUsername((prev) => "");
      setUserBlank((prev) => false);
      setProfileTitle((prev) => "");
      setProfileBlank((prev) => false);
      setMaxChildren(prev => 0)
      setViewOnly(prev => false)
      setSuccessMessage(prev => "")
      setFilterText(prev => "")
      setEditItem(prev => null)
    };
  }, [showUserManager, authToken]);

  const loadUserList = async () => {
    try {
      setLoading((prev) => true);

      const res = await AppService.getChildrenUsers({
        authtoken: authToken,
      });

      if (res && res.data && res.data.status === "OK") {
        setUserList((prev) => [...res.data.data]);
      }

      setLoading((prev) => false);
    } catch (err) {
      setLoading((prev) => false);
      console.error(err);
    }
  };


  const addNewUserClicked = async () => {

    setSystemError(prev => "")

    let error = false
    if (!username || !username?.startsWith("9") || username.trim().length !== 10)
    {
      setUserBlank(prev => true)
      error = true
    }
    if (!profileTitle || profileTitle.trim().length < 3)
    {
      setProfileBlank(prev => true)
      error = true
    }
    if (error)
    {
      return
    }

    try{
      setSubmitting(prev => true)

      const res = await AppService.createChildUser({
        authtoken: authToken,
        username: username,
        profileTitle: profileTitle,
        viewOnly: viewOnly
      })

      if (res && res.data && res.data.status === "OK")
      {
        setSuccessMessage(prev => "کاربر با موفقیت اضافه گردید")

        setUsername(prev => "")
        setProfileTitle(prev => "")
        setMaxChildren(prev => 0)
        setViewOnly(prev => false)

        setTimeout(() => {
          setSuccessMessage(prev => "")
        }, 3000);

        loadUserList()


      }else{
        setSystemError(prev => res?.data?.error || "")
      }

      setSubmitting(prev => false)
  

    }catch(error)
    {
      setSubmitting(prev => false)
    }


  }

  const getFilteredList = (list) => {
    if (!filterText || filterText.trim().length === 0) {
      return list;
    }

    return list.filter(
      (item) =>
        item.profileTitle?.indexOf(filterText) >= 0 ||
        item.username?.indexOf(filterText) >= 0
    );
  };

  return (
    <div className="w-full h-screen overflow-hidden flex flex-col gap-0 justify-start">
      <div
        className={`w-full h-[50px] text-white flex flex-row items-center justify-between px-2 shadow-sm grow-0`}
        style={{
          background: theme.palette.background.paper,
          color: "white", //theme.palette.secondary.main,
        }}
      >
        <span className="w-[22px]"></span>

        <span
          className={`text-[18px] transition-all duration-500 ease-in-out flex items-center gap-2`}
        >
          <span>
            <Diversity3OutlinedIcon />
          </span>

          <span>{"مدیریت زیرمجموعه"}</span>
        </span>

        <IconButton
          onClick={() => setShowUserManager((prev) => false)}
          color="inherit"
        >
          <ArrowBackIosNew color="inherit" sx={{ width: "22px" }} />
        </IconButton>
      </div>

      <div className="w-full h-full flex flex-col gap-0 overflow-hidden relative px-5 py-5">
        <div>
          <div className="w-full relative border rounded-lg border-[#aaa] flex flex-col py-5 pt-8 px-5 gap-1">
            <span
              className="text-gray-200 absolute -top-[15px] right-[10px] px-2 flex items-center gap-1"
              style={{
                background: theme.palette.background.default,
                color: theme.palette.primary.main,
              }}
            >
              <span className="text-2xl"> {" + "} </span>
              <span> {"کاربر جدید "}</span>
            </span>

            <TextBox
              id="username"
              label="نام کاربری / شماره موبایل"
              placeholder="9XXXXXXXXX"
              InputAdornment
              autoComplete={"off"}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="end">
                      <PhoneIphoneOutlinedIcon
                        style={{
                          color: theme.palette.primary.light,
                          width: "22px",
                        }}
                      />
                    </InputAdornment>
                  ),
                },
              }}
              variant="standard"
              value={username}
              onChange={(event) => {
                setUsername(event.target.value?.substring(0,10));
                setUserBlank(
                  !event.target.value || event.target.value?.length < 10
                );
              }}
              disabled={submitting}
              error={userBlank}
            />

            <TextBox
              id="profile"
              label="نام و نام خانوادگی (فارسی)"
              InputAdornment
              autoComplete={"off"}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="end">
                      <PersonOutlineOutlinedIcon
                        style={{
                          color: theme.palette.primary.light,
                          width: "22px",
                        }}
                      />
                    </InputAdornment>
                  ),
                },
              }}
              variant="standard"
              value={profileTitle}
              onChange={(event) => {
                setProfileTitle(event.target.value);
                setProfileBlank(!event.target.value);
              }}
              disabled={submitting}
              error={profileBlank}
            />

            <p className="flex flex-row justify-between items-center pt-5">
              <p className="text-gray-100 text-sm flex flex-row gap-1">
                <span>{"غیرفعال کردن ترید"}</span>
                <span>{"( Read-only )"}</span>
              </p>

              <p className="flex flex-row items-center justify-start w-[95px]">
                <Switch
                  checked={viewOnly}
                  onChange={(event) => setViewOnly(event.target.checked)}
                />
              </p>
            </p>

            <p className="w-full flex justify-center items-center pt-2">
              <Button
                variant="contained"
                fullWidth
                onClick={addNewUserClicked}
                className="flex flex-row items-center justify-center"
                sx={{ height: "45px" }}
              >
                {submitting ? (
                  <span>
                    <CircularProgress
                      disableShrink
                      style={{ color: `black` }}
                      size={28}
                    />
                  </span>
                ) : (
                  <span>{"تعریف کاربر جدید"}</span>
                )}
              </Button>
            </p>

            {systemError?.length > 0 && (
              <p className="w-full text-center text-red-200 bg-red-700 p-2 flex flex-row gap-2">
                <span>{"Error: "}</span>
                <span>{`${systemError}`}</span>
              </p>
            )}

            {successMessage?.length > 0 && (
              <p className="w-full text-center text-green-700 bg-green-200 p-2">
                {`${successMessage}`}
              </p>
            )}
          </div>
        </div>

        {loading && (
          <div className="w-full flex flex-row items-center justify-center z-20 text-gray-300 mt-2">
            <CircularProgress color="inherit" size={"30px"} />
          </div>
        )}

        {!loading && (
          <>
            <span
              className="text-gray-200  flex items-center gap-1 mt-5 mb-2 border-b border-[#aaa] pb-2"
              style={{
                background: theme.palette.background.default,
                color: theme.palette.primary.main,
              }}
            >
              <FormatListBulletedOutlinedIcon />
              <span className="font-semibold w-[120px]">
                {" "}
                {"لیست کاربران  "}
              </span>

              <TextBox
                id="search-text"
                label="جستجو در نام کاربر/موبایل"
                InputAdornment
                autoComplete={"off"}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="end">
                        <PersonSearchOutlinedIcon
                          style={{
                            color: theme.palette.primary.main,
                            width: "22px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
                variant="standard"
                value={filterText}
                onChange={(event) => {
                  setFilterText(event.target.value);
                }}
              />
            </span>

            <div className="w-full h-full overflow-y-scroll flex flex-col gap-3 pt-2  px-5 pb-[200px]">
              {getFilteredList(userList)?.map((user) => (
                <div
                  className="w-full grow-0 shrink-0 rounded-xl"
                  style={{ background: theme.palette.background.paperGrad }}
                >
                  <p className="w-full text-center text-gray-100 flex justify-between items-center px-2 py-1 border-gray-300">
                    <p className="flex flex-col gap-2">
                      <span className="text-white flex flex-row gap-2 items-center">
                        {user.viewOnly && (
                          <DoNotTouchOutlinedIcon
                            sx={{ color: theme.palette.primary.main }}
                          />
                        )}
                        <span>{user.profileTitle}</span>
                      </span>
                      <span>{user.username}</span>
                    </p>

                    <Button
                      variant="outlined"
                      onClick={() => setEditItem((prev) => user)}
                    >
                      <EditOutlinedIcon />
                    </Button>
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <div
        className={`${
          editItem ? "translate-y-0 " : "translate-y-full "
        }  transition-all duration-300 ease-in-out fixed bottom-0 left-0 w-full flex flex-col gap-2 rounded-t-xl border-t`}
        style={{ background: theme.palette.background.paper, zIndex: 100 }}
      >
        <div
          className="flex flex-row items-center justify-between px-2 border-b border-gray-400"
          style={{ color: theme.palette.primary.main }}
        >
          <p className="flex flex-row gap-1">
            <EditOutlinedIcon />
            <span>{"ویرایش کاربر"}</span>
          </p>

          <IconButton
            onClick={() => setEditItem((prev) => null)}
            color="inherit"
          >
            <ClearOutlined color="inherit" />
          </IconButton>
        </div>
        <EditUser
          user={editItem}
          isChild={true}
          reloadCallback={() => {
            loadUserList();
            setTimeout(() => {
              setEditItem((prev) => null);
            }, 1500);
          }}
        />
      </div>
    </div>
  );
}

export default ChildrenManager;
