import { useAtom } from "jotai";
import { urlAtom } from "../atoms/urlAtom";
import { useEffect, useRef, useState } from "react";
import { endSubscription, subscribeForMessage } from "../utils/subscriber";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Numpad from "../components/Numpad";
import { sendMessage } from "../utils/publisher";
import { socketAtom } from "../atoms/socketAtom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import dateFormat from "dateformat";
import LafzTablo from "../components/LafzTablo";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { accountAtom } from "../atoms/accountAtom";
import { liveTabloAtom } from "../atoms/liveTabloAtom";
import { myLafzAtom } from "../atoms/myLafzAtom";
import MyLafz from "../components/MyLafz";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import { toShamsi } from "../utils/date-utils";
import { liveTabloOldAtom } from "../atoms/liveTabloOldAtom";
import { liveTabloAllAtom } from "../atoms/liveTabloAllAtom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";

import { TABLO_LIST as TABLO_LIST_ALL } from "./_index";
import { errorMessageAtom } from "../atoms/errorMessageAtom";
import { dealMessageAtom } from "../atoms/dealMessageAtom";
import TextBox from "../components/TextBox";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { topLafzesAtom } from "../atoms/topLafzesAtom";
import LafzHistoryItem from "../components/LafzHistoryItem";
import LafzHistoryItemTablo from "../components/LafzHistoryItemTablo";
import AppService from "../services/AppService";
import { authTokenAtom } from "../atoms/authTokenAtom";
import { statsAtom } from "../atoms/statsAtom";


function TabloHavale({ TABLO_LIST }) {
  const [url, setUrl] = useAtom(urlAtom);

  const theme = useTheme();

  const [authToken, setAuthToken] = useAtom(authTokenAtom)

  const [buyOpen, setBuyOpen] = useState(false);
  const [sellOpen, setSellOpen] = useState(false);

  const [socket, setSocket] = useAtom(socketAtom);

  const [account, setAccount] = useAtom(accountAtom);

  const [buyOfferText, setBuyOfferText] = useState("");
  const [buyOfferValue, setBuyOfferValue] = useState(0);

  const [sellOfferText, setSellOfferText] = useState("");
  const [sellOfferValue, setSellOfferValue] = useState(0);
  const [numpadBuy, setNumpadBuy] = useState(false);
  const [numpadSell, setNumpadSell] = useState(false);

  const [dealSeqReceived, setDealSeqReceived] = useState([]);

  const [now, setNow] = useState(new Date());

  const [expandBottomBar, setExpandBottomBar] = useState(true);

  const [clickedItem, setClickedItem] = useState(null);

  const lastBuyRef = useRef(null);

  const [buyTablo, setBuyTablo] = useState(TABLO_LIST[0].tablo);
  const [sellTablo, setSellTablo] = useState(TABLO_LIST[0].tablo);

  const [desc, setDesc] = useState("")

  const [buyPrefixDigits, setBuyPrefixDigits] = useState("")
  const [sellPrefixDigits, setSellPrefixDigits] = useState("")

  const [stats, setStats] = useAtom(statsAtom)

  const containerBuyRef = useRef(null)
  const containerSellRef = useRef(null)

  const MAX_PRICE_DIFF = 200


  useEffect(() => {
    if (account) {
      const subLafz = subscribeForMessage(
        "responseLafz",
        responseLafzRecieved,
        "TabloHavale"
      );

      const subTablo = subscribeForMessage(
        "updateTablo",
        updateTabloRecieved,
        "TabloHavale"
      );

      const subDeal = subscribeForMessage(
        "responseDeal",
        responseDealRecieved,
        "TabloHavale",
        account
      );

      const subTopLafzes = subscribeForMessage(
        "updateTopLafzes",
        updateTopLafzesRecieved,
        "TabloHavale"
      );



      const interval = setInterval(() => {
        setNow(() => new Date());
      }, 1000);

      return () => {
        endSubscription(subLafz);
        endSubscription(subTablo);
        endSubscription(subDeal);
        endSubscription(subTopLafzes);

        clearInterval(interval);
      };
    }
  }, [account]);

  useEffect(() => {
    if (url !== "/tablohavale") {
      setBuyOpen(prev=>false);
      setSellOpen(prev=>false);
      buyOfferTextChanged("")
      sellOfferTextChanged("")
      setNumpadBuy(prev=>false)
      setNumpadSell(prev=>false)
      setDesc(prev => "")
      setNomoreDataBuy(prev => false)
      setLafzArrayBuy(prev => [])
      setLoadMoreBuy(prev => false)

      containerBuyRef.current?.scroll({
        top: 0,
        behavior: 'smooth'
      })

      containerSellRef.current?.scroll({
        top: 0,
        behavior: 'smooth'
      })

    }else
    {

    }
  }, [url]);


  useEffect( () => {

    buyTabloChanged(buyTablo)
    sellTabloChanged(sellTablo)

  }, [now])

  useEffect(() => {
    if (buyTablo !== "TCT" && buyTablo !== "TCF" && buyOpen) {
      setDesc((prev) => "");
    }

    if (sellTablo !== "TCT" && sellTablo !== "TCF" && sellOpen) {
      setDesc((prev) => "");
    }
  }, [buyTablo, sellTablo]);


  const [errorMessage, setErrorMesssage] = useAtom(errorMessageAtom)
  const responseLafzRecieved = (msg) => {
    const { status, error } = msg;
    if (status === "FAILED") {
      setErrorMesssage(prev => error);
    }
  };

  const [dealMessage, setDealMessage] = useAtom(dealMessageAtom)
  const responseDealRecieved = (msg, _account) => {
    const { dest, status, error, payload } = msg;


    if (!dest || !dest.find((d) => d === _account?.username)) {
      return;
    }


    if (status === "FAILED") {
      console.log(error);
      setErrorMesssage((prev) => error);
    } else if (status === "OK") {
      // console.log(payload);
      setDealMessage((prev) => {
        if (!dealSeqReceived.find((s) => s === payload.seq)) {
          return [...prev, payload];
        } else {
          return prev;
        }
      });
    }
  };




  const [liveTablo, setLiveTablo] = useAtom(liveTabloAtom);
  const [liveTabloOld, setLiveTabloOld] = useAtom(liveTabloOldAtom);
  const [liveTabloAll, setLiveTabloAll] = useAtom(liveTabloAllAtom);

  const [topLafzes, setTopLafzes] = useAtom(topLafzesAtom)

  const updateTopLafzesRecieved = (msg) => {
    setTopLafzes(prev =>  {return {lafzes: msg.lafzes, lafzesOld: msg.lafzesOld}})
  }
  
  const updateTabloRecieved = (msg) => {
    // console.log(msg)
    setLiveTablo((prev) => msg.liveTablo);
    setLiveTabloOld((prev) => msg.liveTabloOld);
  };



  useEffect(() => {
    if (clickedItem) {
      if (
        !liveTablo.find((r) => r.seq === clickedItem.item.seq && r.volume >= 1)
      ) {
        setClickedItem((prev) => null);
      }
    }
  }, [clickedItem, liveTablo]);

  const buyTabloChanged = (tablo) => {
    let _tablo = tablo
    if (new Date().getHours() >= 15)
    {
      if (tablo === "TCT")
      {
        _tablo = "TCF"
      }
      else if (tablo === "THT")
      {
        _tablo = "THF"
      }
    }

    setBuyTablo(prev => _tablo)
  }

  const sellTabloChanged = (tablo) => {
    let _tablo = tablo
    if (new Date().getHours() >= 15)
    {
      if (tablo === "TCT")
      {
        _tablo = "TCF"
      }
      else if (tablo === "THT")
      {
        _tablo = "THF"
      }
    }

    setSellTablo(prev => _tablo)
  }


  const priceStepsClickedBuy = (price) => {
    const newPrice = (buyOfferText?.length > 0 ? parseInt(buyOfferText) : 0) + (price / 1000);
    buyOfferTextChanged(newPrice.toLocaleString("en"));
  };

  const priceStepsClickedSell = (price) => {

    const newPrice = (sellOfferText?.length > 0 ? parseInt(sellOfferText) : 0) + (price / 1000);
    sellOfferTextChanged(newPrice.toLocaleString("en"));
  };

  const buyOfferTextChanged = (str) => {

    if (!str || str.trim() === "") {
      setBuyOfferValue(prev => 0);
      setBuyOfferText(prev => "")
      setBuyPrefixDigits(prev => "")
      return;
    }

    setBuyPrefixDigits(prev => "")

    const last_deal = stats.stats.tabloPrice.find(r => r.tablo === buyTablo).price

    const two_digits = Math.floor(last_deal / 1_000_000)

    const maxPrice = last_deal + MAX_PRICE_DIFF * 1000
    const minPrice = last_deal - MAX_PRICE_DIFF * 1000

    const num = parseInt(str);

    const p0 = num * 1000 + (two_digits * 1_000_000) 
    const p1 = num * 1000 + ((two_digits+1) * 1_000_000) 
    const p_1 = num * 1000 + ((two_digits-1) * 1_000_000) 

    if (p0 >= minPrice && p0 <= maxPrice)
    {
      setBuyOfferValue(prev =>  p0)
      setBuyPrefixDigits(prev => two_digits.toLocaleString("en"))

    }else if (p1 >= minPrice && p1 <= maxPrice)
    {
      setBuyOfferValue(prev =>  p1)
      setBuyPrefixDigits(prev => (two_digits+1).toLocaleString("en"))

    }else if (p_1 >= minPrice && p_1 <= maxPrice)
    {
      setBuyOfferValue(prev =>  p_1)
      setBuyPrefixDigits(prev => (two_digits-1).toLocaleString("en"))

    }else{
      setBuyOfferValue(prev => 0)
    }

    setBuyOfferText(prev => str)
  };

  const sellOfferTextChanged = (str) => {

    if (!str || str.trim() === "") {
      setSellOfferValue(prev => 0);
      setSellOfferText(prev => "")
      setSellPrefixDigits(prev => "")
      return;
    }

    setSellPrefixDigits(prev => "")

    const last_deal = stats.stats.tabloPrice.find(r => r.tablo === buyTablo).price

    const two_digits = Math.floor(last_deal / 1_000_000)

    const maxPrice = last_deal + MAX_PRICE_DIFF * 1000
    const minPrice = last_deal - MAX_PRICE_DIFF * 1000

    const num = parseInt(str);

    const p0 = num * 1000 + (two_digits * 1_000_000) 
    const p1 = num * 1000 + ((two_digits+1) * 1_000_000) 
    const p_1 = num * 1000 + ((two_digits-1) * 1_000_000) 

    if (p0 >= minPrice && p0 <= maxPrice)
    {
      setSellOfferValue(prev =>  p0)
      setSellPrefixDigits(prev => two_digits.toLocaleString("en"))

    }else if (p1 >= minPrice && p1 <= maxPrice)
    {
      setSellOfferValue(prev =>  p1)
      setSellPrefixDigits(prev => (two_digits+1).toLocaleString("en"))

    }else if (p_1 >= minPrice && p_1 <= maxPrice)
    {
      setSellOfferValue(prev =>  p_1)
      setSellPrefixDigits(prev => (two_digits-1).toLocaleString("en"))

    }else{
      setSellOfferValue(prev => 0)
    }

    setSellOfferText(prev => str)

  };

  const [showBuyConfirm, setShowBuyConfirm] = useState(false);
  const [buyVolume, setBuyVolume] = useState(1);

  const buyClicked = () => {

    if (buyOfferValue <= 0) {
      setNumpadBuy(true);
      return;
    }
    setNumpadBuy(false);
    setShowBuyConfirm(true);
  };

  const submitBuy = () => {
    const msg = {
      type: "requestLafz",
      price: buyOfferValue,
      volume: buyVolume,
      dir: "buy",
      tablo: buyTablo,
      desc: buyTablo === "TCT" || buyTablo === "TCF" ? desc : ""
    };

    if (sendMessage(msg, socket)) {
      buyOfferTextChanged("");
      setBuyVolume(prev => 1);
      setNumpadBuy(prev => false);
      setShowBuyConfirm(prev => false);
      setBuyOpen(prev => false);
      setDesc(prev => "")
    }
  };

  const [showSellConfirm, setShowSellConfirm] = useState(false);
  const [sellVolume, setSellVolume] = useState(1);

  const [myLafz, setMyLafz] = useAtom(myLafzAtom);

  const sellClicked = () => {
    if (sellOfferValue <= 0) {
      setNumpadSell(true);
      return;
    }

    setNumpadSell(false);
    setShowSellConfirm(true);
  };

  const submitSell = () => {
    const msg = {
      type: "requestLafz",
      price: sellOfferValue,
      volume: sellVolume,
      dir: "sell",
      tablo: sellTablo,
      desc: sellTablo === "TCT" || sellTablo === "TCF" ? desc : ""
    };

    if (sendMessage(msg, socket)) {
      sellOfferTextChanged("");
      setSellVolume(prev => 1);
      setNumpadSell(prev => false);
      setShowSellConfirm(prev => false);
      setSellOpen(prev => false);
      setDesc(prev => "")
    }
  };

  const [clickedLafzSeq, setClickedLafzSeq] = useState(0);
  const lafzClicked = (lafz) => {

    if (buyOpen) {

      buyOfferTextChanged(`${lafz.price.toLocaleString("en").substring(3,6)}`);
    } else if (sellOpen) {
      sellOfferTextChanged(`${lafz.price.toLocaleString("en").substring(3,6)}`);
    }

    if (clickedLafzSeq === lafz.seq) {
      lafzDoubleClicked(lafz);
      setClickedLafzSeq(0);
    } else {
      setClickedLafzSeq(lafz.seq);
      setTimeout(() => {
        setClickedLafzSeq((prev) => 0);
      }, 500);
    }
  };

  const lafzDoubleClicked = (lafz) => {
    // console.log(lafz);
  };

  const cancelLafz = (lafz) => {
    sendMessage(
      { type: "requestCancelLafz", tablo: lafz.tablo, seq: lafz.seq },
      socket
    );
  };

  const volumeClicked = (kilo, item) => {
    if (account.viewOnly)
    {
      return
    }

    setClickedItem({ kilo, item });
  };

  const sendDeal = () => {
    if (clickedItem) {
      const msg = {
        type: "requestDeal",
        kilo: clickedItem.kilo,
        seq: clickedItem.item.seq,
        tablo: clickedItem.item.tablo,
      };

      if (sendMessage(msg, socket)) {
        setClickedItem(null);
      }
    }
  };

  useEffect(() => {
    if (!expandBottomBar) {
      setNumpadBuy(false);
      setNumpadSell(false);
    }
  }, [expandBottomBar]);


  const getMinSeqBuy = (array) => {
    let min = 1_000_000_000;
    for (const lafz of array) {
      if (lafz.seq < min) {
        min = lafz.seq;
      }
    }

    for (const _lafz of topLafzes.lafzes)
    {
      if (_lafz.seq < min && _lafz.dir === "buy") {
        min = _lafz.seq;
      }
    }
    return min;
  };

  const [lafzArrayBuy, setLafzArrayBuy] = useState([])
  const [loadMoreBuy, setLoadMoreBuy] = useState(false)
  const [loadingBuy, setLoadingBuy] = useState(false)

  useEffect(() => {
    if (loadMoreBuy && url === "/tablohavale") {
      const minSeq = getMinSeqBuy(lafzArrayBuy);
      loadLafzArrayBuy(minSeq);
    }
  }, [loadMoreBuy, lafzArrayBuy, url]);



  const buyScrolled = (e) => {

    if (loadingBuy || loadMoreBuy)
      {
        e.preventDefault()
        return
      }

    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;

    if (Math.abs(bottom) < 10){
      setLoadMoreBuy((prev) => true);
      setTimeout(() => {
        setLoadMoreBuy((prev) => false);
      }, 3000);
    }
  }

  const [nomoreDataBuy, setNomoreDataBuy] = useState(false);
  const loadLafzArrayBuy = async (maxSeq) => {
    try {

      // console.log("loadLafzArrayBuy", maxSeq)

      if (nomoreDataBuy && maxSeq > 0) {
        setLoadingBuy(prev => false);
        return;
      }

      setLoadingBuy(prev => true);
      const res = await AppService.getLafzHistory({
        authtoken: authToken,
        maxSeq: maxSeq,
        filterLafzDir : ["buy"]
      });
      if (res && res.data && res.data.status === "OK") {
        if (res.data.data.length === 0) {
          setNomoreDataBuy((prev) => true);
          setLoadingBuy((prev) => false);
        } else {
          const data = res.data.data.sort((a, b) => b.seq - a.seq);
          setLafzArrayBuy((prev) =>
            [...prev, ...data].sort((a, b) => b.seq - a.seq)
          );
        }
        setLoadMoreBuy((prev) => false);
      }

      setLoadingBuy(prev => false);
    } catch (err) {
      console.error(err);
      setLoadingBuy(prev => false);
    }
  };






  const getMinSeqSell = (array) => {
    let min = 1_000_000_000;
    for (const lafz of array) {
      if (lafz.seq < min) {
        min = lafz.seq;
      }
    }

    for (const _lafz of topLafzes.lafzes)
    {
      if (_lafz.seq < min && _lafz.dir === "sell") {
        min = _lafz.seq;
      }
    }
    return min;
  };

  const [lafzArraySell, setLafzArraySell] = useState([])
  const [loadMoreSell, setLoadMoreSell] = useState(false)
  const [loadingSell, setLoadingSell] = useState(false)

  useEffect(() => {
    if (loadMoreSell && url === "/tablohavale") {
      const minSeq = getMinSeqSell(lafzArraySell);
      loadLafzArraySell(minSeq);
    }
  }, [loadMoreSell, lafzArraySell, url]);



  const sellScrolled = (e) => {

    if (loadingSell || loadMoreSell)
      {
        e.preventDefault()
        return
      }

    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;

    if (Math.abs(bottom) < 10){
      setLoadMoreSell((prev) => true);
      setTimeout(() => {
        setLoadMoreSell((prev) => false);
      }, 3000);
    }
  }

  const [nomoreDataSell, setNomoreDataSell] = useState(false);
  const loadLafzArraySell = async (maxSeq) => {
    try {

      if (nomoreDataSell && maxSeq > 0) {
        setLoadingSell(prev => false);
        return;
      }

      setLoadingSell(prev => true);
      const res = await AppService.getLafzHistory({
        authtoken: authToken,
        maxSeq: maxSeq,
        filterLafzDir : ["sell"]
      });
      if (res && res.data && res.data.status === "OK") {
        if (res.data.data.length === 0) {
          setNomoreDataSell((prev) => true);
          setLoadingSell((prev) => false);
        } else {
          const data = res.data.data.sort((a, b) => b.seq - a.seq);
          setLafzArraySell((prev) =>
            [...prev, ...data].sort((a, b) => b.seq - a.seq)
          );
        }
        setLoadMoreSell((prev) => false);
      }

      setLoadingSell(prev => false);
    } catch (err) {
      console.error(err);
      setLoadingSell(prev => false);
    }
  };



  return (
    <div
      className="w-full h-full flex flex-col bg-opacity-50 "
      style={{ background: theme.palette.background.default }}
    >
      <div
        id="top-bar"
        className="w-full grow-0 shrink-0 h-[30px] flex flex-row text"
      >
        <p className="flex justify-center items-center text-green-400 w-full border-b border-green-400 ">
          {"خرید"}
        </p>
        <p className="flex justify-center items-center text-red-400 w-full border-b border-red-400 ">
          {"فروش"}
        </p>
      </div>

      <div
        id="main-view"
        className="w-full h-full flex flex-row overflow-y-hidden grow-1"
      >
        <div
          className="w-full border-l border-[#2b3035] p-2 overflow-y-scroll relative"
          onScroll={(event) => buyScrolled(event)}
          ref={containerBuyRef}
        >
          {!liveTablo.find((item) => item.dir === "buy" && (item.nameSpace === account?.nameSpace || (!item.nameSpace && !account?.nameSpace))) && (
            <div className=" w-full text-white flex flex-row overflow-hidden items-center justify-center opacity-100 mb-[5px]">
              <span className="border-b flex flex-row items-center justify-center px-3 py-0 text-sm gap-2">
                <span>{"لفظ خرید نیست"}</span>
                <span>
                  <NotInterestedOutlinedIcon sx={{ width: "20px" }} />
                </span>
              </span>
            </div>
          )}

          <div className="flex flex-col gap-2 w-full pb-[30px] relative ">
            <>
              {liveTabloAll
                .filter(
                  (item) =>
                    item.dir === "buy" &&
                    TABLO_LIST.find((r) => r.tablo === item.tablo)
                )
                .map((item, index) => (
                  <>
                    {liveTablo?.find((r) => r.seq === item.seq) ? (
                      <LafzTablo
                        onClick={() => lafzClicked(item)}
                        onVolumeClicked={(kilo) => volumeClicked(kilo, item)}
                        liveTablo={liveTablo}
                        liveTabloOld={liveTabloOld}
                        item={item}
                        index={index}
                        now={now}
                      />
                    ) : (
                      <LafzHistoryItemTablo item={item} onClick={() => lafzClicked(item)} />
                    )}
                  </>
                ))}

              {loadingBuy && (
                <div className="w-full flex items-center justify-center absolute bottom-0 left-0 text-gray-300">
                  <CircularProgress color="inherit" size={"20px"} />
                </div>
              )}

              {lafzArrayBuy.map((item) => (
                <LafzHistoryItemTablo item={item} onClick={() => lafzClicked(item)} />
              ))}
            </>
          </div>
        </div>

        <div
          className="w-full p-2 overflow-y-scroll relative "
          ref={containerSellRef}
          onScroll={(event) => sellScrolled(event)}
        >
          {!liveTablo.find((item) => item.dir === "sell" && (item.nameSpace === account?.nameSpace || (!item.nameSpace && !account?.nameSpace))) && (
            <div className=" w-full text-white flex flex-row overflow-hidden items-center justify-center opacity-100 mb-[5px]">
              <span className="border-b flex flex-row items-center justify-center px-3 py-0 text-sm gap-2">
                <span>{"لفظ فروش نیست"}</span>
                <span>
                  <NotInterestedOutlinedIcon sx={{ width: "20px" }} />
                </span>
              </span>
            </div>
          )}
          <div className="flex flex-col gap-2 w-full pb-[30px] relative ">
            <>
              {liveTabloAll
                .filter(
                  (item) =>
                    item.dir === "sell" &&
                    TABLO_LIST.find((r) => r.tablo === item.tablo)
                )
                .map((item, index) => (
                  <>
                    {liveTablo?.find((r) => r.seq === item.seq) ? (
                      <LafzTablo
                        onClick={() => lafzClicked(item)}
                        onVolumeClicked={(kilo) => volumeClicked(kilo, item)}
                        liveTablo={liveTablo}
                        liveTabloOld={liveTabloOld}
                        item={item}
                        index={index}
                        now={now}
                      />
                    ) : (
                      <LafzHistoryItemTablo item={item}  onClick={() => lafzClicked(item)}/>
                    )}
                  </>
                ))}

              {loadingSell && (
                <div className="w-full flex items-center justify-center absolute bottom-0 left-0 text-gray-300">
                  <CircularProgress color="inherit" size={"20px"} />
                </div>
              )}

              {lafzArraySell.map((item) => (
                <LafzHistoryItemTablo item={item}  onClick={() => lafzClicked(item)}/>
              ))}

            </>
          </div>
        </div>
      </div>

      <div
        className={`text-white `}
      >
        <div className={`${myLafz?.length > 1 ? " " : "hidden "} flex justify-center items-center`}>
          <span className={`${expandBottomBar ? "-rotate-90 " : "rotate-90 "} transition-all duration-300 ease-in-out delay-500 `} onClick={() => setExpandBottomBar(prev => !prev)}>
            <ArrowBackIosNewIcon/>
            </span>
        </div>

        <div className={`${expandBottomBar || myLafz?.length === 1 ? "h-fit " : "h-0 "} flex flex-col border-t border-gray-600` }>
        {myLafz?.map((lafz) => (
          <MyLafz
            item={lafz}
            now={now}
            onCancel={() => {
              if (lafz) cancelLafz(lafz);
            }}
          />
        ))}
        </div>  

      </div>

      <div
        id="bottom-bar"
        className={`${
          account?.viewOnly ? "opacity-50 " : " "
        }  w-full flex flex-row grow-0 shrink-0 h-[70px] bg-opacity-100 shadow-gray-500 shadow-sm items-center justify-center`}
        style={{ background: theme.palette.background.paper }}
      >
        <div className="w-full h-full flex items-center justify-center ">
          <div
            onClick={() => setBuyOpen((prev) => !account?.viewOnly && true)}
            className="w-[110px] py-2 flex flex-row items-center justify-center rounded-xl text-green-400 border border-green-400 bg-opacity-70"
          >
            <span>
              <AddOutlinedIcon sx={{ width: "22px" }} />
            </span>
            <span>{"لفظ خرید"}</span>
          </div>
        </div>

        <div className="w-full h-full flex items-center justify-center">
          <div
            onClick={() => setSellOpen((prev) => !account?.viewOnly && true)}
            className="w-[110px] py-2 flex flex-row items-center justify-center rounded-xl text-red-400 border border-red-400 bg-opacity-70"
          >
            <span>
              <AddOutlinedIcon sx={{ width: "22px" }} />
            </span>
            <span>{"لفظ فروش"}</span>
          </div>
        </div>
      </div>

      <div
        className={`${
          buyOpen ? "translate-y-0 " : "translate-y-[500px] "
        } fixed bottom-0  left-0 transition-all duration-300 ease-in-out w-full flex flex-col gap-2 z-20 py-3 px-5 pb-[30px] rounded-t-3xl border-t border-gray-500`}
        style={{ background: theme.palette.background.paper }}
      >
        <div className="flex flex-row justify-between items-start">
          <div className="w-[200px]">
            <Numpad
              priceStepsClicked={(price) => priceStepsClickedBuy(price)}
              numpadOpen={numpadBuy}
              setNumpadOpen={(open) => {
                setNumpadBuy(open);
                if (open) setNumpadSell(false);
              }}
              text={buyOfferText}
              label={"پیشنهاد خرید شما ( ۳ رقم آخر )"}
              textChanged={buyOfferTextChanged}
              prefixDigits={buyPrefixDigits}
              error={buyOfferText?.length > 0 && buyPrefixDigits?.length === 0}
    
            />
          </div>

          <div>
            <IconButton
              onClick={() => {
                setBuyOpen((prev) => false);
                setNumpadBuy((prev) => false);
                setDesc((prev) => "");
              }}
            >
              <CloseOutlinedIcon sx={{ color: "#ddd" }} />
            </IconButton>
          </div>
        </div>

        <div className="w-full h-full flex flex-row items-center justify-center gap-2">
          {TABLO_LIST.map((item) => (
            <div
              className={`flex justify-center flex-col text-sm gap-0  items-center w-full  border rounded-lg transition-all duration-300 ease-in-out ${
                item.tablo === buyTablo
                  ? "border-green-400 text-green-400 "
                  : "border-gray-400 text-gray-400 "
              }`}
              onClick={() => buyTabloChanged(item.tablo)}
            >
              <span>{`${item.title}`}</span>
              <span> {item.icon} </span>
            </div>
          ))}
        </div>

        <div className="w-full h-full flex flex-row items-center justify-center gap-2  ">
          {[1, 2, 3].map((kilo) => (
            <div
              className={`flex justify-center items-center gap-2 w-full h-[35px] border rounded-lg transition-all duration-300 ease-in-out ${
                kilo === buyVolume
                  ? "border-green-400 text-green-400 "
                  : "border-gray-400 text-gray-400 "
              }`}
              onClick={() => setBuyVolume(kilo)}
            >
              <span>{`${kilo}`}</span>
              <span className="text-xs">{"کیلو"}</span>
            </div>
          ))}
        </div>

        {(buyTablo === "TCT" || buyTablo === "TCF") && (
          <div className="w-full">
            <TextBox
              id="search-text"
              fullWidth
              label="توضیحات / شرایط"
              autoComplete={"off"}
              variant="standard"
              value={desc}
              onChange={(event) => {
                setDesc((prev) => event.target.value?.substring(0, 40));
              }}
            />
          </div>
        )}

        <div className="flex justify-center items-center h-full w-full mt-[10px]">
          <div
            onClick={buyClicked}
            className="w-full py-3 flex items-center justify-center rounded-xl bg-green-700 text-white"
          >
            {"ارسال خرید"}
          </div>
        </div>
      </div>

      <div
        className={`${
          sellOpen ? "translate-y-0 " : "translate-y-[500px] "
        } fixed bottom-0 left-0 transition-all duration-300 ease-in-out w-full flex flex-col gap-2 z-20 py-3 px-5 pb-[30px] rounded-t-3xl border-t border-gray-500`}
        style={{ background: theme.palette.background.paper }}
      >
        <div className="flex flex-row justify-between items-start">
          <div className="w-[200px]">
            <Numpad
              priceStepsClicked={(price) => priceStepsClickedSell(price)}
              numpadOpen={numpadSell}
              setNumpadOpen={(open) => {
                setNumpadSell(open);
                if (open) setNumpadBuy(false);
              }}
              text={sellOfferText}
              label={"پیشنهاد فروش شما ( ۳ رقم آخر )"}
              textChanged={sellOfferTextChanged}
              prefixDigits={sellPrefixDigits}
              error={sellOfferText?.length > 0 && sellPrefixDigits?.length === 0}
            />
          </div>

          <div>
            <IconButton
              onClick={() => {
                setSellOpen((prev) => false);
                setNumpadSell((prev) => false);
                setDesc((prev) => "");
              }}
            >
              <CloseOutlinedIcon sx={{ color: "#ddd" }} />
            </IconButton>
          </div>
        </div>

        <div className="w-full h-full flex flex-row items-center justify-center gap-2 ">
          {TABLO_LIST.map((item) => (
            <div
              className={`flex justify-center flex-col text-sm gap-0  items-center w-full  border rounded-lg transition-all duration-300 ease-in-out ${
                item.tablo === sellTablo
                  ? "border-red-400 text-red-400 "
                  : "border-gray-400 text-gray-400 "
              }`}
              onClick={() => sellTabloChanged(item.tablo)}
            >
              <span>{`${item.title}`}</span>
              <span> {item.icon} </span>
            </div>
          ))}
        </div>

        <div className="w-full h-full flex flex-row items-center justify-center gap-2 ">
          {[1, 2, 3].map((kilo) => (
            <div
              className={`flex justify-center items-center gap-2 w-full h-[35px] border rounded-lg transition-all duration-300 ease-in-out ${
                kilo === sellVolume
                  ? "border-red-400 text-red-400 "
                  : "border-gray-400 text-gray-400 "
              }`}
              onClick={() => setSellVolume(kilo)}
            >
              <span>{`${kilo}`}</span>
              <span className="text-xs">{"کیلو"}</span>
            </div>
          ))}
        </div>

        {(sellTablo === "TCT" || sellTablo === "TCF") && (
          <div className="w-full">
            <TextBox
              id="search-text"
              fullWidth
              label="توضیحات / شرایط"
              autoComplete={"off"}
              variant="standard"
              value={desc}
              onChange={(event) => {
                setDesc((prev) => event.target.value?.substring(0, 40));
              }}
            />
          </div>
        )}

        <div className="flex justify-center items-center h-full w-full mt-[10px]">
          <div
            onClick={sellClicked}
            className="w-full py-3 flex items-center justify-center rounded-xl bg-red-800 text-white"
          >
            {"ارسال فروش"}
          </div>
        </div>
      </div>

      <Dialog
        open={errorMessage?.length > 0}
        onClose={() => setErrorMesssage(prev => "")}
      >
        <DialogTitle
          sx={{ fontSize: "18px", color: "#ff4242" }}
          className="flex gap-1"
        >
          <ErrorOutlineIcon />
          {"خطا"}
        </DialogTitle>
        <DialogContent
          sx={{
            color: "#fafafa",
            padding: "10px",
            width: "100%",
            fontSize: "16px",
            lineHeight: "28px",
          }}
        >
          <div className="w-full text-justify">{errorMessage}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMesssage(prev => "")} color="white">
            بستن
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dealMessage?.length > 0}
        onClose={() => setDealMessage(prev => [])}
        maxWidth={"xs"}
      >
        <DialogTitle
          sx={{ fontSize: "16px", color: theme.palette.secondary.main }}
          className="flex gap-1"
        >
          <HandshakeOutlinedIcon />
          {"معاملات زیر با موفقیت انجام شد"}
        </DialogTitle>
        <DialogContent
          sx={{
            color: "#ffeded",
            padding: "10px",
            fontSize: "16px",
            lineHeight: "28px",
          }}
        >
          <div className="flex flex-col gap-2 w-full px-2 overflow-y-scroll">
            {dealMessage.map((deal) => (
              <div className="flex flex-col gap-0 border-b border-gray-500 pb-1 mb-2 fade-in">
                <p className="flex flex-row gap-2 items-center">
                  <span className="opacity-60">{"نوع معامله :"}</span>
                  <span
                    className="flex flex-row items-center gap-1"
                    style={{ color: theme.palette.secondary.main }}
                  >
                    <span>
                      {TABLO_LIST_ALL.find((r) => r.tablo === deal.tablo)
                        ?.title || ""}
                    </span>
                    <span>
                      {TABLO_LIST_ALL.find((r) => r.tablo === deal.tablo)
                        ?.icon || ""}
                    </span>
                  </span>
                </p>
                <p className="flex flex-row gap-2 items-center">
                  <span className="opacity-60">{"خریدار :"}</span>
                  <span>{` ${deal.buyerProfile} `}</span>
                </p>
                <p className="flex flex-row gap-2 items-center">
                  <span className="opacity-60">{"فروشنده :"}</span>
                  <span>{` ${deal.sellerProfile} `}</span>
                </p>
                <p className="flex flex-row gap-2 items-center">
                  <span className="opacity-60">{"قیمت :"}</span>
                  <p className="flex flex-row gap-1 items-center">
                    <span>{` ${deal.price.toLocaleString("en")} `}</span>
                    <span>{" تومان "}</span>
                  </p>
                </p>
                <p className="flex flex-row gap-2 items-center">
                  <span className="opacity-60">{"مقدار :"}</span>
                  <p className="flex flex-row gap-1 items-center">
                    <span>{` ${deal.volume} `}</span>
                    <span>{" کیلو "}</span>
                  </p>
                </p>

                {deal.desc?.length > 0 && (
                  <p className="flex flex-row gap-2 items-center">
                    <span className="opacity-60">{"شرط :"}</span>
                    <p className="flex flex-row gap-1 items-center">
                      <span>{` ${deal.desc} `}</span>
                    </p>
                  </p>
                )}

                <p className="flex flex-row gap-2 items-center">
                  <span className="opacity-60">{"برای :"}</span>
                  <span dir="ltr" className="tracking-wider">{`${toShamsi(
                    deal.settlementDate
                  )}`}</span>
                </p>
                <p className="flex flex-row gap-2 items-center">
                  <span className="opacity-60">{"شماره پیگیری :"}</span>
                  <span className="text-sm opacity-90">{` ${deal.seq} `}</span>
                </p>
                <p className="flex flex-row gap-2 items-center">
                  <span className="opacity-60">{"زمان معامله :"}</span>
                  <span dir="ltr" className="text-sm opacity-90">{`${toShamsi(
                    deal.timeStamp
                  )} - ${dateFormat(deal.timeStamp, "HH:MM:ss TT")}`}</span>
                </p>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDealMessage(prev => [])} color="white">
            بستن
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showBuyConfirm}>
        <DialogTitle sx={{ fontSize: "16px", color: "#eee" }}>
          لفظ زیر مورد تایید است؟
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "white",
              fontSize: "16px",
              // borderBottom: "3px solid #00b530",
              paddingBottom: "1px",
              textAlign: "center",
              paddingBottom: "2px",
            }}
            className="flex flex-col gap-2"
          >
            <p className="flex flex-row gap-2 items-center">
              <span className="text-green-400 text-lg">{" خرید "}</span>
              <p className="flex flex-row gap-1 text-green-400 items-center">
                <span>
                  {TABLO_LIST.find((r) => r.tablo === buyTablo)?.title || ""}
                </span>
                <span>
                  {TABLO_LIST.find((r) => r.tablo === buyTablo)?.icon || ""}
                </span>
              </p>
            </p>

            <p
              className="flex flex-row gap-2 text-[#fff]"
              style={{ borderBottom: "3px solid #00b530" }}
            >
              <span>{`${buyVolume}`}</span>
              <span className="text-gray-300">{" کیلو "}</span>
              <span className="text-gray-300">{" در قیمت "}</span>
              <span>{`${buyOfferValue?.toLocaleString("en")}`}</span>
              <span className="text-gray-300">{" تومان "}</span>
            </p>

            {desc?.length > 0 && (
              <div
                className="w-full overflow-hidden break-world border-b-1 rounded text-sm p-1 my-1 flex flex-row items-center border border-yellow-300"
                style={{ color: theme.palette.primary.main }}
              >
                <InfoOutlinedIcon
                  sx={{
                    width: "20px",
                    marginLeft: "5px",
                    color: theme.palette.primary.main,
                  }}
                />

                <span className="">{`${desc}`}</span>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontSize: "14px", color: "#ccc" }}
            onClick={() => setShowBuyConfirm(false)}
          >
            خیر انصراف میدهم
          </Button>
          <Button
            sx={{ fontSize: "14px" }}
            color="primary"
            variant="contained"
            onClick={submitBuy}
          >
            بله مورد تایید است
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showSellConfirm}>
        <DialogTitle sx={{ fontSize: "16px", color: "#eee" }}>
          لفظ زیر مورد تایید است؟
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "white",
              fontSize: "16px",
              // borderBottom: "3px solid #d60f0f",
              paddingBottom: "1px",
              textAlign: "center",
              paddingBottom: "2px",
            }}
            className="flex flex-col gap-2"
          >
            <p className="flex flex-row gap-2 items-center">
              <span className="text-red-400 text-lg">{" فروش "}</span>
              <p className="flex flex-row gap-1 text-red-400 items-center">
                <span>
                  {TABLO_LIST.find((r) => r.tablo === sellTablo)?.title || ""}
                </span>
                <span>
                  {TABLO_LIST.find((r) => r.tablo === sellTablo)?.icon || ""}
                </span>
              </p>
            </p>

            <p
              className="flex flex-row gap-2 text-[#fff]"
              style={{ borderBottom: "3px solid #d60f0f" }}
            >
              <span>{`${sellVolume}`}</span>
              <span className="text-gray-300">{" کیلو "}</span>
              <span className="text-gray-300">{" در قیمت "}</span>
              <span>{`${sellOfferValue?.toLocaleString("en")}`}</span>
              <span className="text-gray-300">{" تومان "}</span>
            </p>

            {desc?.length > 0 && (
              <div
                className="w-full overflow-hidden break-world border-b-1 rounded text-sm p-1 my-1 flex flex-row items-center border border-yellow-300"
                style={{ color: theme.palette.primary.main }}
              >
                <InfoOutlinedIcon
                  sx={{
                    width: "20px",
                    marginLeft: "5px",
                    color: theme.palette.primary.main,
                  }}
                />

                <span className="">{`${desc}`}</span>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontSize: "14px", color: "#ccc" }}
            onClick={() => setShowSellConfirm(false)}
          >
            خیر انصراف میدهم
          </Button>
          <Button
            sx={{ fontSize: "14px" }}
            color="primary"
            variant="contained"
            onClick={submitSell}
          >
            بله مورد تایید است
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={clickedItem !== null}>
        <DialogTitle sx={{ fontSize: "16px", color: "#eee" }}>
          <span>{" معامله "}</span>
          <span
            className={`${
              clickedItem?.item?.dir === "sell"
                ? "text-green-400 "
                : "text-red-400 "
            } `}
          >
            {clickedItem?.item?.dir === "sell" ? " خرید " : " فروش "}
          </span>
          زیر مورد تایید است؟
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "white",
              fontSize: "16px",
              // borderBottom: `3px solid ${
              //   clickedItem?.item?.dir === "sell" ? "#00b530 " : "#d60f0f "
              // }  `,
              textAlign: "center",
              paddingBottom: "2px",
            }}
            className="flex flex-col gap-2"
          >
            <p
              className={`flex flex-row gap-1 ${
                clickedItem?.item?.dir === "sell"
                  ? "text-green-400 "
                  : "text-red-400 "
              } items-center`}
            >
              <span>
                {TABLO_LIST.find((r) => r.tablo === clickedItem?.item?.tablo)
                  ?.title || ""}
              </span>
              <span>
                {TABLO_LIST.find((r) => r.tablo === clickedItem?.item?.tablo)
                  ?.icon || ""}
              </span>
            </p>

            <p
              className="flex flex-row gap-1 items-center"
              style={{
                borderBottom: `3px solid ${
                  clickedItem?.item?.dir === "sell" ? "#00b530 " : "#d60f0f "
                }  `,
              }}
            >
              <span>{`${clickedItem?.kilo || ""}`}</span>
              <span className="text-gray-300">{" کیلو "}</span>
              <span className="text-gray-300">{" در قیمت "}</span>
              <span>{`${
                clickedItem?.item?.price?.toLocaleString("en") || ""
              }`}</span>
              <span className="text-gray-300">{" تومان "}</span>
            </p>

            {clickedItem?.item?.desc?.length > 0 && (
              <div
                className="w-full overflow-hidden break-world border-b-1 rounded text-sm p-1 my-1 flex flex-row items-center border border-yellow-300"
                style={{ color: theme.palette.primary.main }}
              >
                <InfoOutlinedIcon
                  sx={{
                    width: "20px",
                    marginLeft: "5px",
                    color: theme.palette.primary.main,
                  }}
                />

                <span className="">{`${clickedItem?.item?.desc}`}</span>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontSize: "14px", color: "#ccc" }}
            onClick={() => setClickedItem(null)}
          >
            خیر انصراف میدهم
          </Button>
          <Button
            sx={{ fontSize: "14px" }}
            color="primary"
            variant="contained"
            onClick={sendDeal}
          >
            بله مورد تایید است
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TabloHavale;
