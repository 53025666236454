import { useAtom } from "jotai";
import { urlAtom } from "../atoms/urlAtom";
import { getAllPages, getPageByUrl, TABLO_LIST } from "./_index";
import { useEffect, useRef, useState } from "react";
import AppService from "../services/AppService";
import { accountAtom } from "../atoms/accountAtom";
import { authTokenAtom } from "../atoms/authTokenAtom";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Switch,
  useTheme,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import MyDealItem from "../components/MyDealItem";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Calendar, CalendarProvider, DatePicker, TimePicker } from "zaman";
import { toShamsi } from "../utils/date-utils";
import TextBox from "../components/TextBox";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import { CancelOutlined } from "@mui/icons-material";

const DEAL_DIR = [
  { dir: "buy", title: " معاملات خرید" },
  { dir: "sell", title: " معاملات فروش" },
];

function MyDeals() {
  const [dealArray, setDealArray] = useState([]);

  const [loading, setLoading] = useState(false);

  const [url, setUrl] = useAtom(urlAtom);

  const [account, setAccount] = useAtom(accountAtom);
  const [authToken, setAuthToken] = useAtom(authTokenAtom);

  const [loadMore, setLoadMore] = useState(false);

  const containerRef = useRef(null);
  const lastElementRef = useRef(null);
  const veryLastElementRef = useRef(null);

  const [lastSeq, setLastSeq] = useState(0);

  const [nomoreData, setNomoreData] = useState(false);

  const [firstTime, setFirstTime] = useState(false);

  const [showScrollToEnd, setShowScrollToEnd] = useState(false);

  const [showFilter, setShowFilter] = useState(false);

  const [filterTablo, setFilterTablo] = useState([]);
  const [filterDir, setFilterDir] = useState([]);

  const [filterDate, setFilterDate] = useState("today");
  const [filterText, setFilterText] = useState("");

  const [showFilterText, setShowFilterText] = useState(false)

  const [sumBuy, setSumBuy] = useState(0);
  const [sumSell, setSumSell] = useState(0);

  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());

  const [refreshData, setRefreshData] = useState(false);

  const [filterShowChildren, setFilterShowChildren] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (authToken && url === "/mydeals") {
      loadDealArray();
      setFirstTime((prev) => true);
    }

    return () => {
      setDealArray((prev) => []);
      setNomoreData((prev) => false);
      setLastSeq(0);
      setShowScrollToEnd((prev) => false);
    };
  }, [
    authToken,
    url,
    filterDir,
    filterTablo,
    filterDate,
    filterShowChildren,
    refreshData,
  ]);

  useEffect(() => {
    if (url !== "/mydeals") {
      setShowFilter(false);
      setFilterDir((prev) => []);
      setFilterTablo((prev) => []);
      setFilterDate((prev) => "today");
      setFilterShowChildren((prev) => false);
      setFilterText((prev) => "");
      setShowFilterText(prev => false)

      setShowCalendar((prev) => false);
      setStartDate(
        (prev) => new Date(new Date().setDate(new Date().getDate() - 1))
      );
      setEndDate((prev) => new Date());
    }
  }, [url]);

  useEffect(() => {
    if (account && dealArray) {
      let sum_buy = 0;
      let sum_sell = 0;

      const filterdArray = getFilteredText(dealArray, account)

      for (const deal of filterdArray) {
        if (deal.buyerIssuer === account.username || deal.buyerAccount === account.username) {
          sum_buy += deal.volume;
        } else {
          sum_sell += deal.volume;
        }
      }

      setSumBuy((prev) => sum_buy);
      setSumSell((prev) => sum_sell);
    }
  }, [dealArray, account, filterText]);

  useEffect(() => {
    if (lastSeq > 0) {
      if (lastElementRef.current) {
        containerRef.current.style.overflowY = "hidden";
        containerRef.current.scrollTo({
          top: lastElementRef.current.offsetTop - 20,
          behavior: firstTime ? "smooth" : "instant",
        });
        containerRef.current.style.overflowY = "scroll";
      }
    }
  }, [lastSeq]);

  const scrolled = (event) => {
    if (loading || loadMore) {
      event.preventDefault();
      return;
    }

    setShowScrollToEnd(
      (prev) => veryLastElementRef?.current?.getBoundingClientRect()?.top > 900
    );
  };

  const getMaxSeq = (array) => {
    let max = 0;
    for (const deal of array) {
      if (deal.seq > max) {
        max = deal.seq;
      }
    }
    return max;
  };

  const loadDealArray = async () => {
    try {
      setLoading(true);

      let start_date = new Date();
      let end_date = new Date();
      if (filterDate === "today") {
        // do nothing
      } else if (filterDate === "yesterday") {
        start_date.setDate(start_date.getDate() - 1);
        end_date.setDate(end_date.getDate() - 1);
      } else {
        start_date = startDate;
        end_date = endDate;
      }

      const res = await AppService.getMyDeals({
        authtoken: authToken,
        filterTablo,
        filterDir,
        startDate: start_date,
        endDate: end_date,
        showChildren: filterShowChildren,
      });

      if (res && res.data && res.data.status === "OK") {
        const data = res.data.data.sort((a, b) => a.seq - b.seq);
        setLastSeq((prev) => getMaxSeq(data));
        setDealArray((prev) =>
          [...data].sort((a, b) => a.seq - b.seq)
        );
      }

      setLoading((prev) => false);
    } catch (err) {
      console.error(err);
      setLoading((prev) => false);
    }
  };

  const scrollToEnd = () => {
    containerRef.current.scrollTo({
      top: veryLastElementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (filterDate !== "custom") {
      setShowCalendar((prev) => false);
    }
  }, [filterDate]);

  const getFilteredText = (array, _account) => {
    return array.filter((item) => {
      return item.buyerIssuer === _account?.username ||
        item.sellerIssuer === _account?.username
        ? item.buyerIssuer === _account?.username
          ? item.sellerProfile.indexOf(filterText?.trim()) >= 0
          : item.buyerProfile.indexOf(filterText?.trim()) >= 0
        : item.buyerAccount === _account?.username
        ?  item.buyerProfile.substring(0, item.buyerProfile.indexOf("("))?.indexOf(filterText?.trim()) >= 0 ||
          item.sellerProfile.indexOf(filterText?.trim()) >= 0
        : item.sellerProfile.substring(0, item.sellerProfile.indexOf("("))?.indexOf(filterText?.trim()) >= 0 ||
          item.buyerProfile.indexOf(filterText?.trim()) >= 0;
    });
  };

  return (
    <div
      className="w-full h-full relative flex flex-col"
      style={{ background: theme.palette.background.default }}
    >
      <div
        className="flex flex-col gap-1 shadow-sm  border-b pb-1"
        style={{ background: theme.palette.background.paper }}
      >
        <div className="w-full grow-0 flex flex-row items-center justify-between gap-1 ">
          <div className="flex flex-row items-center gap-2 px-5 h-[30px]">
            <div
              onClick={() => setFilterDate((prev) => "today")}
              className={`${
                filterDate === "today"
                  ? "text-yellow-400 border-yellow-400 "
                  : "text-gray-400 border-gray-400 "
              } transition-all duration-300 ease-in-out border px-5 py-0 flex justify-center items-center rounded-lg h-full `}
            >
              {"امروز"}
            </div>

            <div
              onClick={() => setFilterDate((prev) => "yesterday")}
              className={`${
                filterDate === "yesterday"
                  ? "text-yellow-400 border-yellow-400 "
                  : "text-gray-400 border-gray-400 "
              } transition-all duration-300 ease-in-out border px-5 py-0 flex justify-center items-center rounded-lg h-full`}
            >
              {"دیروز"}
            </div>

            <div
              onClick={() => setShowCalendar((prev) => !prev)}
              className={`${
                filterDate === "custom"
                  ? "text-yellow-400 border-yellow-400 "
                  : showCalendar
                  ? "text-white border-white "
                  : "text-gray-400 border-gray-400 "
              } transition-all border duration-300 ease-in-out gap-2 px-2 py-0 flex justify-center items-center rounded-lg h-full`}
            >
              <span className="text-center">{"تاریخ "}</span>
              <CalendarMonthOutlinedIcon sx={{ width: "20px" }} />
            </div>

            <span style={showFilterText ? {color: theme.palette.primary.main} : {color: "white"}}>
              <IconButton onClick={() => setShowFilterText(prev => !prev)} color="inherit">
                <PersonSearchOutlinedIcon color="inherit"/>
              </IconButton>
            </span>

          </div>

          <div
            className={`pl-5 ${
              filterTablo.length > 0 || filterDir.length > 0
                ? "text-yellow-400 "
                : "text-gray-50 "
            }`}
          >
            <IconButton
              onClick={() => setShowFilter((prev) => !prev)}
              color="inherit"
            >
              <TuneOutlinedIcon />
            </IconButton>
          </div>
        </div>

        {filterDate === "custom" && !showCalendar && (
          <p className="flex flex-row text-yellow-400 items-center justify-center gap-2 pb-1">
            <span className="text-gray-300">{"از تاریخ"}</span>

            <span className="tracking-wider">{toShamsi(startDate)}</span>

            <span className="text-gray-300">{"تا تاریخ"}</span>

            <span className="tracking-wider">{toShamsi(endDate)}</span>
          </p>
        )}
      </div>

      <div
        className={`${
          showCalendar ? "top-[48px]  opacity-100 " : "-top-[500px]  opacity-0 "
        } transition-all duration-300 ease-in-out fixed w-full flex flex-col items-center justify-center bg-black bg-opacity-80 rounded-b-xl z-20 text-white`}
      >
        <p className="flex flex-row text-white items-center justify-center gap-2 pb-1">
          <span className="text-slate-400">{"از تاریخ"}</span>

          <span className="tracking-wider">{toShamsi(startDate)}</span>

          <span className="text-slate-400">{"تا تاریخ"}</span>

          <span className="tracking-wider">{toShamsi(endDate)}</span>
        </p>

        {url === "/mydeals" && (
          <CalendarProvider locale="fa" round="x4" accentColor="#6374ae">
            <Calendar
              defaultValue={new Date()}
              onChange={(e) => {
                setStartDate((prev) => e.from);
                setEndDate((prev) => e.to);
              }}
              weekends={[6]}
              from={startDate}
              to={endDate}
              range
            />
          </CalendarProvider>
        )}

        <div className="flex flex-row items-center justify-center m-1 mb-3 gap-2">
          <span
            onClick={() => setShowCalendar((prev) => false)}
            className="flex flex-row gap-2 rounded-full  w-[150px] h-[35px] items-center justify-center  border-slate-400 text-slate-200"
          >
            <span>{"بستن تقویم"}</span>
          </span>

          <span
            onClick={() => {
              setFilterDate((prev) => "custom");
              setShowCalendar((prev) => false);
              setRefreshData((prev) => !prev);
            }}
            className="flex flex-row gap-2 rounded-full w-[150px] h-[35px] items-center justify-center text-[#222]"
            style={{ background: theme.palette.primary.main }}
          >
            <span>{"تایید تاریخ"}</span>
            <DoneOutlinedIcon />
          </span>
        </div>
      </div>

      <div className={`${showFilterText ? "h-[70px] opacity-100 " : "h-0 opacity-0 "} overflow-hidden transition-all duration-300 ease-in-out  w-full  grow-0 shrink-0 fade-in border-b border-gray-400 rounded-b-xl`}>
        <div className="flex flex-row gap-4 items-baseline text-white w-full justify-center">
          <TextBox
            id="search-text"
            label="جستجو در نام خریدار / فروشنده"
            InputAdornment
            autoComplete={"off"}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="end">
                    <PersonSearchOutlinedIcon
                      style={{
                        color: theme.palette.primary.light,
                        width: "22px",
                      }}
                    />
                  </InputAdornment>
                ),
              },
            }}
            variant="standard"
            value={filterText}
            onChange={(event) => {
              setFilterText(event.target.value);
            }}
          />

            <IconButton onClick={() => {setFilterText(prev => ""); setShowFilterText(prev => false)}}>
              <ClearOutlinedIcon/>
            </IconButton>

        </div>
      </div>

      <div
        ref={containerRef}
        onScroll={(event) => scrolled(event)}
        className="w-full h-full flex flex-col gap-2 overflow-y-scroll py-[20px] px-[10px] "
      >
        {loading && (
          <div className="absolute top-[100px] left-0 w-full flex flex-row items-center justify-center z-20 text-gray-300">
            <CircularProgress color="inherit" size={"20px"} />
          </div>
        )}

        {!loading && dealArray.length === 0 && (
          <div className="w-full h-full flex flex-col gap-1 justify-center items-center">
            <span className="text-gray-400 border border-gray-600 px-5 py-2">
              {"معامله ای یافت نشد"}
            </span>
          </div>
        )}

        {getFilteredText(dealArray, account)?.map((deal, index) => (
          <div
            ref={deal.seq === lastSeq ? lastElementRef : null}
            key={`deal-${deal.seq}`}
            className="fade-in"
          >
            <MyDealItem item={deal} searchText={filterText} />
          </div>
        ))}
        <div ref={veryLastElementRef}></div>
      </div>

      <div
        className="w-full grow-0 shrink-0 flex flex-row items-center justify-around gap-1 shadow-sm h-[70px] pb-[10px] border-t border-b border-gray-600"
        style={{ background: theme.palette.background.paper }}
      >
        <p className="flex flex-row items-baseline gap-2 bg-green-500 p-2 bg-opacity-15 rounded-xl">
          <span className="text-gray-300 text-sm">{" مجموع خرید : "}</span>
          <span className="text-white">{sumBuy}</span>
          <span className="text-[11px] text-gray-300">{"کیلو"}</span>
        </p>

        <p className="flex flex-row items-baseline gap-2 bg-red-500 p-2 bg-opacity-15 rounded-xl">
          <span className="text-gray-300 text-sm">{" مجموع فروش : "}</span>
          <span className="text-white">{sumSell}</span>
          <span className="text-[11px] text-gray-300">{"کیلو"}</span>
        </p>
      </div>

      <div
        className={`${
          showFilter ? "translate-y-0" : "translate-y-[500px] "
        }  transition-all duration-300 ease-in-out fixed w-full pb-[20px] left-0 bottom-[70px] rounded-t-3xl border-t `}
        style={{ zIndex: 100, background: theme.palette.background.default }}
      >
        <div className="w-full h-full flex flex-col gap-4">
          <p className="px-5 py-2 text-xl text-white border-b flex flex-row justify-between items-center">
            <span className="flex flex-row gap-1">
              <span>
                <TuneOutlinedIcon />
              </span>
              <span>{"فیلترها"}</span>
            </span>
            <span
              className={`${
                filterTablo.length > 0 ||
                filterDir.length > 0 ||
                filterShowChildren ||
                filterText?.trim().length > 0
                  ? " "
                  : "hidden "
              }`}
            >
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  setFilterTablo((prev) => []);
                  setFilterDir((prev) => []);
                  setFilterShowChildren((prev) => false);
                  setFilterText((prev) => "");
                }}
              >
                {"حذف فیلترها"}
              </Button>
            </span>
            <span>
              <IconButton onClick={() => setShowFilter((prev) => false)}>
                <ClearOutlinedIcon />
              </IconButton>
            </span>
          </p>

          <div className="flex flex-row gap-4 px-4">
            {DEAL_DIR.map((item) => (
              <div
                key={`filter-${item.dir}`}
                className={`flex justify-center flex-col gap-0 py-2 items-center w-full  border rounded-lg transition-all duration-300 ease-in-out ${
                  filterDir.find((r) => r === item.dir)
                    ? "border-yellow-400 text-yellow-400 "
                    : "border-gray-400 text-gray-400 "
                }`}
                onClick={() => {
                  setFilterDir((prev) => {
                    if (prev.find((r) => r === item.dir)) {
                      return prev.filter((r) => r !== item.dir);
                    } else {
                      return [...prev, item.dir];
                    }
                  });
                }}
              >
                <span>{`${item.title}`}</span>
              </div>
            ))}
          </div>

          <div className="flex flex-row gap-4 px-4">
            {TABLO_LIST.map((item) => (
              <div
                key={`filter-${item.tablo}`}
                className={`flex justify-center flex-col gap-0  items-center w-full  border rounded-lg transition-all duration-300 ease-in-out ${
                  filterTablo.find((r) => r === item.tablo)
                    ? "border-yellow-400 text-yellow-400 "
                    : "border-gray-400 text-gray-400 "
                }`}
                onClick={() => {
                  setFilterTablo((prev) => {
                    if (prev.find((r) => r === item.tablo)) {
                      return prev.filter((r) => r !== item.tablo);
                    } else {
                      return [...prev, item.tablo];
                    }
                  });
                }}
              >
                <span>{`${item.title}`}</span>
                <span> {item.icon} </span>
              </div>
            ))}
          </div>

          {!account?.parent && (
            <div className="flex flex-row gap-4 items-center text-white w-full justify-center">
              <span id="label-show-children">
                {"نمایش معاملات زیرمجموعه ها"}
              </span>
              <span>
                <Switch
                  checked={filterShowChildren}
                  onChange={(event) =>
                    setFilterShowChildren((prev) => event.target.checked)
                  }
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyDeals;
