import {
  TextField,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Paper,
  IconButton,
  Icon,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  AccountCircleOutlined,
  Key,
  Lock,
  LockOutlined,
  PersonOutline,
  Visibility,
  VisibilityOff,
  VpnKey,
  VpnKeyOutlined,
} from "@mui/icons-material";
import UserService from "../services/UserService";
import { useAtom } from "jotai";
import { authTokenAtom } from "../atoms/authTokenAtom";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";
import Main from "./Main";
import OfflineAlert from "./OfflineAlert";
import { showSettingsAtom } from "../atoms/showSettingsAtom";
import Settings from "./Settings";
import { showUserManagerAtom } from "../atoms/showUserManagerAtom";
import { showChildrenManagerAtom } from "../atoms/showChildrenManagerAtom";
import UserManager from "./UserManager";
import ChildrenManager from "./ChildrenManager";



function Layout() {
  const theme = useTheme();

  const [showSettings, setShowSettings] = useAtom(showSettingsAtom)
  const [showUserManager, setShowUserManager] = useAtom(showUserManagerAtom);
  const [showChildrenManager, setShowChildrenManager] = useAtom(showChildrenManagerAtom);


  return (
    <div className="w-full h-screen overflow-hidden flex flex-col gap-0 justify-between">
      <div>
        <TopBar/>
        <OfflineAlert/>
      </div>

      <Main/>  

      <BottomBar/>


      <div className={`${showSettings ? "translate-y-0 " : "translate-y-full "} transition-all duration-500 ease-in-out fixed left-0 top-0 h-screen w-screen overflow-hidden `} style={{background: theme.palette.background.default}} >
          <Settings/>
      </div>

      <div className={`${showUserManager ? "translate-y-0 " : "translate-y-full "} transition-all duration-500 ease-in-out fixed left-0 top-0 h-screen w-screen overflow-hidden `} style={{background: theme.palette.background.default}} >
          <UserManager/>
      </div>

      <div className={`${showChildrenManager ? "translate-y-0 " : "translate-y-full "} transition-all duration-500 ease-in-out fixed left-0 top-0 h-screen w-screen overflow-hidden `} style={{background: theme.palette.background.default}} >
          <ChildrenManager/>
      </div>

      


    </div>
  );
}

export default Layout;
