import { atom} from 'jotai'
import { liveTabloAtom } from './liveTabloAtom'
import { topLafzesAtom } from './topLafzesAtom'
import { accountAtom } from './accountAtom'


export const liveTabloAllAtom = atom( (get) => {
    const liveTablo = get(liveTabloAtom)
    const topLafzes = get(topLafzesAtom)    
    const account = get(accountAtom)

    const merge = [...liveTablo];

    if (topLafzes?.lafzes?.length > 0) {
      for (const item of topLafzes.lafzes) {
        if (!merge.find((m) => m.seq === item.seq)) {
          merge.push(item);
        }
      }
    }
   
    return merge.filter(item => item.nameSpace === account?.nameSpace || (!item.nameSpace && !account?.nameSpace)).sort((a, b) => b.seq - a.seq);

})
